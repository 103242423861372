import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import SweetAlert from "react-bootstrap-sweetalert"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import {
  GetAll,
  deletefunc,
  postfunc,
  putfunc,
} from "../../services/mainService"
const Service = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [serviceObj, setserviceObj] = useState({
    serviceName: "",
    servicePrice: "",
    customerID: "",
    employeeID: "",
    workType: "",
    quantity: "",
    workStatus: "",
    prductionStatus: "",
  })
  const [customers, setcustomers] = useState([])
  const [selectedCustomer, setselectedCustomer] = useState({})
  const [selectedEmployee, setselectedEmployee] = useState({})
  const [selectedEmployeeProduction, setselectedEmployeeProduction] = useState(
    {}
  )
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [employee, setemployee] = useState([])
  const [showDesign, setshowDesign] = useState(true)
  const [showPrint, setshowPrint] = useState(true)
  const [serviceType, setserviceType] = useState("")
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [deleteObj, setdeleteObj] = useState({})
  const token = localStorage.getItem("token")

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    async function onload() {
      const { data: customerslist } = await GetAll("customers", headers)
      let checkcustomers = Array.isArray(customerslist)
      if (checkcustomers) {
        setcustomers(customerslist)
      }
      const { data: employeeList } = await GetAll("employee", headers)

      let checkemployee = Array.isArray(employeeList)
      if (checkemployee) {
        setemployee(employeeList)
      }

      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        setcurrentUser(user)
      }
    }
    onload()
  }, [])

  let serviceData = []
  const { data: servicelist } = useSwr("service", fetchAll)

  let chechArray = Array.isArray(servicelist)
  if (chechArray == true) {
    serviceData = servicelist
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setserviceObj({
      serviceName: "",
      servicePrice: "",
      customerID: "",
      employeeID: "",
      workType: "",
      quantity: "",
      workStatus: "",
      prductionStatus: "",
    })
    setselectedCustomer({})
    setselectedEmployee({})
    setselectedEmployeeProduction({})
    setEditCondition(false)
    setdisabled(false)
    setload(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setserviceObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()

      serviceObj.workType = serviceType
      if (serviceObj.workType == "both" || serviceObj.workType == "design") {
        if (selectedEmployee.value == undefined) {
          return notify("pls select employee designed", "error")
        }
      }

      if (serviceObj.workType == "both" || serviceObj.workType == "print") {
        if (selectedEmployeeProduction.value == undefined) {
          return notify("pls select employee print", "error")
        }
      }

      const token = localStorage.getItem("token")

      serviceObj.employeeID = selectedEmployee.value
      serviceObj.ProductionEmployeeID = selectedEmployeeProduction.value

      if (editCondition) {
        if (serviceObj.ProductionEmployeeID == undefined) {
          delete serviceObj.ProductionEmployeeID
        }
        if (serviceObj.employeeID == undefined) {
          delete serviceObj.employeeID
        }

        serviceObj.customerID = selectedCustomer.value
        serviceObj.userID = currentUser._id
        setload(true)
        const { data } = await putfunc("service", serviceObj, headers)
        console.log(data.info)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("service", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
          setselectedEmployee({})
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (Object.keys(selectedCustomer).length === 0)
          return toast.warning("please choose a customer")

        serviceObj.customerID = selectedCustomer.value
        serviceObj.userID = currentUser._id

        if (serviceObj.ProductionEmployeeID == undefined) {
          delete serviceObj.ProductionEmployeeID
        }

        if (serviceObj.employeeID == undefined) {
          delete serviceObj.employeeID
        }
        setload(true)
        // return console.log("serviceObj", serviceObj)
        const { data } = await postfunc("service", serviceObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)
          setselectedEmployee({})
          notify(message, "success")
          mutate("service", fetchAll)
          setserviceObj({
            date: new Date(),
            description: "",
            amount: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
      setload(false)
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleEdit = async transData => {
    try {
      let data = { ...transData }
      console.log(data)
      setserviceObj(data)
      setserviceObj({
        serviceName: data.serviceName,
        servicePrice: data.servicePrice,
        // customerID: data.customerID,
        // employeeID: "",
        workType: data.workType,
        quantity: data.quantity,
        workStatus: data.workStatus,
        prductionStatus: data.prductionStatus,
      })
      setEditCondition(true)
      // setserviceObj(data.workType)
      setIsOpen(true)
      setselectedCustomer({
        label: data.customerID?.name + "-" + data.customerID.phone,
        value: data.customerID?._id,
      })
      setselectedEmployee({
        label: data.employeeID?.name,
        value: data.employeeID?._id,
      })
      setselectedEmployeeProduction({
        label: data.ProductionEmployeeID?.name,
        value: data.ProductionEmployeeID?._id,
      })
    } catch (error) {
      console.log(error.message)
    }
  }
  const CustomerIDOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedCustomer(e)
      }
    } catch (error) {
      console.log(e)
      console.log(error)
    }
  }
  const EmployeeIDOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedEmployee(e)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const EmpProductionOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedEmployeeProduction(e)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const serviceTypeOnchange = e => {
    try {
      if (e.target.value) {
        if (e.target.value == "design") {
          setshowDesign(true)
          setshowPrint(false)
          setserviceObj({
            serviceName: serviceObj.serviceName,
            servicePrice: serviceObj.servicePrice,
            customerID: serviceObj.customerID,
            employeeID: serviceObj.employeeID,
            workType: serviceObj.workType,
            quantity: serviceObj.quantity,
            workStatus: "",
            prductionStatus: "none",
          })
          setselectedEmployeeProduction({})
        } else if (e.target.value == "print") {
          setshowPrint(true)
          setshowDesign(false)
          setserviceObj({
            serviceName: serviceObj.serviceName,
            servicePrice: serviceObj.servicePrice,
            customerID: serviceObj.customerID,
            employeeID: serviceObj.employeeID,
            workType: serviceObj.workType,
            quantity: serviceObj.quantity,
            workStatus: "none",
            prductionStatus: "",
          })
          setselectedEmployee({})
        } else if (e.target.value == "both") {
          setshowPrint(true)
          setshowDesign(true)
          setserviceObj({
            serviceName: serviceObj.serviceName,
            servicePrice: serviceObj.servicePrice,
            customerID: serviceObj.customerID,
            employeeID: serviceObj.employeeID,
            workType: serviceObj.workType,
            quantity: serviceObj.quantity,
            workStatus: "",
            prductionStatus: "",
          })
          setselectedEmployeeProduction({})
          setselectedEmployee({})
        }
        setserviceType(e.target.value)
        // setselectedEmployeeProduction(e)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDelete = data => {
    setdeleteObj(data)
    setsweetAlertConfirm(true)
  }

  const UserData = {
    columns: [
      {
        label: "serviceName",
        field: "serviceName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      {
        label: "servicePrice",
        field: "servicePrice",
        sort: "asc",
        width: 60,
      },
      {
        label: "AmountPaid",
        field: "AmountPaid",
        sort: "asc",
        width: 60,
      },
      {
        label: "Balance",
        field: "Balance",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 80,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: serviceData?.map(cust => {
      const data = { ...cust }
      data.customerName = data.customerID?.name
      data.servicePrice = "$" + data.servicePrice
      data.AmountPaid = "$" + data.AmountPaid
      data.Balance = "$" + data.Balance

      data.date = moment(data.createdAt).format("lll")
      if (data.Status == "percialPaid") {
        data.Status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else if (data.Status == "fullPaid") {
        data.Status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else {
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Service" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Add Service </p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Select Customer</Label>
                          <Select
                            name="studentID"
                            value={selectedCustomer}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => CustomerIDOnachnge(e)}
                            options={customers?.map((cust, index) => {
                              return {
                                label: cust.name + "-" + cust.phone,
                                value: cust._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="serviceName"
                            label="service Name"
                            placeholder="Enter service Name ...."
                            type="text"
                            value={serviceObj.serviceName}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter service Name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            label="servicePrice"
                            name="servicePrice"
                            placeholder="Enter Service Price"
                            value={serviceObj.servicePrice}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  price"
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            label="quantity"
                            name="quantity"
                            placeholder="Enter quantity"
                            value={serviceObj.quantity}
                            onChange={e => handleChange(e)}
                            errorMessage="enter  quantity"
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="select"
                            name="workType"
                            label="Service"
                            placeholder="Enter Service"
                            value={serviceObj.workType}
                            onChange={e => serviceTypeOnchange(e)}
                            errorMessage="enter  Service"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">Select Service Type</option>
                            <option value="print">Print</option>
                            <option value="design">Design</option>
                            <option value="both">design & print</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    {showDesign ? (
                      <Row className="mt-3">
                        <Col>
                          <FormGroup>
                            <Label>Select Designer </Label>
                            <Select
                              name="employeeID"
                              value={selectedEmployee}
                              // isClearable={true}
                              isSearchable={true}
                              onChange={e => EmployeeIDOnachnge(e)}
                              options={employee?.map((employe, index) => {
                                return {
                                  label: employe.name,
                                  value: employe._id,
                                  key: index,
                                }
                              })}
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <AvField
                              type="select"
                              name="workStatus"
                              label="Design Status"
                              placeholder="Enter Design Status"
                              value={serviceObj.workStatus}
                              onChange={e => handleChange(e)}
                              errorMessage="enter  design status"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select design Status</option>
                              <option value="assigned">assigned</option>
                              <option value="progress">progress</option>
                              <option value="done">done</option>
                              <option value="pending">pending</option>
                              <option value="block">block</option>
                              <option value="none">none</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    {showPrint ? (
                      <Row className="mt-3">
                        <Col>
                          <FormGroup>
                            <Label>Select Production Employee</Label>
                            <Select
                              name="ProductionEmployeeID"
                              value={selectedEmployeeProduction}
                              // isClearable={true}
                              isSearchable={true}
                              onChange={e => EmpProductionOnachnge(e)}
                              options={employee?.map((employe, index) => {
                                return {
                                  label: employe.name,
                                  value: employe._id,
                                  key: index,
                                }
                              })}
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <AvField
                              type="select"
                              name="prductionStatus"
                              label="prductionStatus"
                              placeholder="Enter prduction Status"
                              value={serviceObj.prductionStatus}
                              onChange={e => handleChange(e)}
                              errorMessage="enter  prduction Status"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select prduction Status </option>
                              <option value="assigned">assigned</option>
                              <option value="progress">progress</option>
                              <option value="done">done</option>
                              <option value="pending">pending</option>
                              <option value="block">block</option>
                              <option value="none">none</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={load}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : "Save Service"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's customers record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">Service Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-end"
                      >
                        <i className="ri-add-line"></i> Add Service
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={UserData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {sweetAlertConfirm ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                try {
                  // return  console.log(IncomeObj)
                  const { data } = await deletefunc(
                    "service",
                    deleteObj,
                    headers
                  )
                  const { status, message } = { ...data }
                  if (status) {
                    toast.success(message)
                    setsweetAlertConfirm(false)
                    mutate("service", fetchAll)
                  } else {
                    toast.error(message)
                  }
                } catch (error) {
                  toast.error(error.message)
                }
              }}
              onCancel={() => setsweetAlertConfirm(false)}
            >
              Are you sure to delete this data
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Service
