import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  deletefunc,
} from "../services/mainService"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import jwtDecode from "jwt-decode"
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import axios from "axios"
export const receiptContext = createContext()
receiptContext.displayName = "receiptContext"

const ReceiptContextProvider = props => {
  const [receiptObj, setreceiptObj] = useState({
    studentID: "",
    feeID: "",
    phonePaid: "",
    phoneReceipt: "",
    AmountPaid: "",
    datePaid: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [feeData, setfeeData] = useState([])
  const [feeTemp, setfeeTemp] = useState([])
  const [studentData, setStudentData] = useState([])
  const [currentUser, setcurrentUser] = useState({})
  const [selectedStudent, setselectedStudent] = useState({})

  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const onload = async () => {
      try {
        const { data } = await GetAll("fee", headers)
        const { data: students } = await GetAll("student", headers)
        let checkfee = Array.isArray(data)
        let checkstudents = Array.isArray(students)
        if (checkfee) {
          setfeeData(data)
          setfeeTemp(data)
        }
        if (checkstudents) {
          setStudentData(students)
        }
        const token = localStorage.getItem("token")
        if (token) {
          const user = jwtDecode(token)
          setcurrentUser(user)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    onload()
  }, [])
  const location = useLocation()
  Auhtentication(location.pathname)

  let { data: receiptList } = useSwr("receipt", fetchAll)
  let receiptData = []

  let chechArray = Array.isArray(receiptList)
  if (chechArray == true) {
    receiptData = receiptList
  }

  const notify = (message, type) => toast[type](message)

  const tog_standard = () => {
    setIsOpen(!isOpen)
    setreceiptObj({
      phonePaid: "",
      phoneReceipt: "",
      AmountPaid: "",
      datePaid: "",
    })
    setEditCondition(false)
  }
  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (
        selectedStudent.value == undefined ||
        selectedStudent.value == null ||
        selectedStudent.value == ""
      ) {
        return notify("please select student", "error")
      }
      receiptObj.studentID = selectedStudent.value
      if (editCondition) {
        const { data } = await putfunc("receipt", receiptObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("receipt", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setreceiptObj({
            studentID: "",
            feeID: "",
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        receiptObj.userID = currentUser._id
        // return console.log("receiptObj",receiptObj)
        const { data } = await postfunc("receipt", receiptObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("receipt", fetchAll)

          notify(message, "success")
          setIsOpen(false)
          setreceiptObj({
            studentID: "",
            feeID: "",
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }
  const handleChange = e => {
    setreceiptObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  // const StudentIDOnachnge = (e) => {
  //   try {
  //     console.log(e)
  //     if (e.value) {
  //       setstudentID(e);
  //       // setenrollmentObj({
  //       //   studentID: e.value,
  //       //   courseID: enrollmentObj.courseID,
  //       //   classID: enrollmentObj.classID,
  //       //   Amount: enrollmentObj.Amount,
  //       //   enrolledDate:enrollmentObj.enrolledDate,
  //       //   enrolledStatus: enrollmentObj.enrolledStatus,
  //       // })
  //     }

  //   } catch (error) {
  //     console.log(e)
  //     console.log(error)
  //   }

  // };
  const StudentONChenge = e => {
    console.log("value", e.value)
    if (e.value) {
      setselectedStudent(e)
      const studentFees = feeTemp?.filter(
        i => i.studentID?._id == e.value && i.feeStatus !== "fullPaid"
      )

      setfeeData(studentFees)

      if (studentFees.length == 0) {
        receiptObj.feeID = ""
      }

      setreceiptObj({
        studentID: e.value,
        feeID: receiptObj.feeID,
        phonePaid: receiptObj.phonePaid,
        phoneReceipt: receiptObj.phoneReceipt,
        AmountPaid: "",
        datePaid: receiptObj.datePaid,
      })
    }
  }
  const FeeONChenge = e => {
    if (e.target.value !== "") {
      const feeobj = feeTemp.find(fee => fee._id == e.target.value)
      console.log("feeobj", feeobj)

      setreceiptObj({
        studentID: receiptObj.studentID,
        feeID: e.target.value,
        phonePaid: receiptObj.phonePaid,
        phoneReceipt: receiptObj.phoneReceipt,
        AmountPaid: feeobj?.feeBalance,
        datePaid: receiptObj.datePaid,
      })
    }
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.studentID = data.studentID?._id

    setselectedStudent({
      label:
        transData.studentID?.studentName + "-" + transData.studentID?.stdID,
      value: transData.studentID?._id,
    })
    data.feeID = data.feeID?._id
    data.datePaid = moment(data.datePaid).format("YYYY-MM-DD[T]HH:mm:ss")
    // data.datePaid = moment(data.startedDate).format("YYYY-MM-DD");

    setEditCondition(true)
    setreceiptObj(data)
    setIsOpen(true)
  }
  const handleDelete = data => {
    console.log(data)
    setreceiptObj(data)
    setsweetAlertConfirm(true)
  }
  return (
    <receiptContext.Provider
      value={{
        handleEdit,
        handleChange,
        StudentONChenge,
        FeeONChenge,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        feeData,
        studentData,
        receiptData,
        editCondition,
        receiptObj,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        selectedStudent,
      }}
    >
      {props.children}
    </receiptContext.Provider>
  )
}

export default ReceiptContextProvider
