import { AvField, AvForm } from "availity-reactstrap-validation"
import { useContext } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import "../Dashboard/datatables.scss"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify"
import "toastr/build/toastr.min.css"
import { usersContext } from "../../context/userContext"
const UsersForm = () => {
  const {
    collapse,
    usersObj,
    isOpen,
    editCondition,
    tog_standard,
    HandleSubmitBtn,
    handleChange,
    handleEdit,
    handleCheckBoxChange,
    collapseToggle,
    btnCancelTogle,
    userPermessions,
    Users,
    setuserImage,
    userImage,
    setisFits,
    isFits,
    setusersObj,
    handlechangeImage,
    disabled,
    load,
    roles,
    handleshow,
    showpassword,
    showicon,
    employee,
    currentBrand,
  } = useContext(usersContext)
  return (
    <Row>
      <Col sm={12}>
        <Card className="position-sticky">
          <CardHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
          >
            {/* <h4>User Roles</h4> */}
            <Button
              color="success"
              outline
              className="float-end text-white  waves-effect waves-light"
              onClick={collapseToggle}
            >
              Add New User
            </Button>
          </CardHeader>

          <Collapse isOpen={collapse}>
            <div id="basic-collapse">
              <CardBody>
                {/* <h5>Personal Information</h5>
                    <hr /> */}
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="employeeID"
                          label="Select Employee "
                          value={usersObj.employeeID}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          errorMessage="please select Employee"
                          validate={{
                            required: {
                              value: currentBrand == "academy" ? false : true,
                            },
                          }}
                        >
                          <option value="">Select Employee</option>

                          {employee?.map(emp => (
                            <option value={emp._id}>{emp.name}</option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <AvField
                          type="text"
                          name="name"
                          label="Name"
                          value={usersObj.name}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          errorMessage="please name"
                          placeholder="Enter fullname"
                          validate={{ required: { value: true } }}
                        ></AvField>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <AvField
                          type="number"
                          name="phone"
                          label="Phone"
                          value={usersObj.phone}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          errorMessage="please Enter Phone"
                          placeholder="Enter Phone"
                          validate={{ required: { value: true } }}
                        ></AvField>
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <AvField
                          type="text"
                          name="username"
                          label="Username"
                          value={usersObj.username}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          errorMessage="please username"
                          placeholder="Enter username"
                          validate={{ required: { value: true } }}
                        ></AvField>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md="5">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="userType"
                          label="User Type"
                          value={usersObj.userType}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          errorMessage="please select role"
                          validate={{ required: { value: true } }}
                        >
                          <option value="">Select role</option>

                          {roles?.map(std => (
                            <option value={std.role}>{std.role}</option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <div>
                        <Label>password</Label>
                        <InputGroup>
                          <AvField
                            type={showpassword ? "text" : "password"}
                            value={usersObj.password}
                            name="password"
                            onChange={e => handleChange(e)}
                            className="form-control"
                            required
                            placeholder="enter password"
                            validate={{ required: { value: true } }}
                          />
                          <span
                            className="input-group-append"
                            onClick={() => handleshow()}
                          >
                            <span className="input-group-text">
                              {" "}
                              <i className={showicon}></i>
                            </span>
                          </span>
                        </InputGroup>
                      </div>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Button
                          style={{
                            background: "#14213D",
                            color: "white",
                            border: "#0A928C",
                          }}
                          disabled={disabled}
                          className="btn  mt-4 m-2 "
                          type="submit"
                        >
                          {load ? "saving..." : " Save User"}
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Button
                          onClick={btnCancelTogle}
                          className="btn btn-danger mt-4 m-2 "
                          type="button"
                        >
                          Close
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <hr></hr>
                          <h5>User Permissions</h5> */}
                  {/* <Row className="mt-4 ml-4">
                            {userPermessions?.map((item, index) =>
                              item.subMenu && item.subMenu.length ? (
                                <Col>
                                  <p>{item.name}</p>
                                  {item.subMenu?.map((sub, sub_index) => (
                                    <FormGroup key={sub_index}>
                                      <div className="checkbox checkbox-primary ">
                                        <input
                                          onChange={e =>
                                            handleCheckBoxChange(e)
                                          }
                                          type="checkbox"
                                          name="permissions"
                                          className="form-check-input"
                                          value={sub.path}
                                          // value={usersObj.permissions[sub.path]}
                                          id={"checkbox" + index + sub_index}
                                        />
                                        <label
                                          htmlFor={
                                            "checkbox" + index + sub_index
                                          }
                                          className="cr"
                                        >
                                          {sub.name}
                                        </label>
                                      </div>
                                    </FormGroup>
                                  ))}
                                </Col>
                              ) : (
                                <Col key={index}>
                                  <FormGroup key={index}>
                                    <div className="checkbox checkbox-primary form-check   ">
                                      <input
                                        onChange={e => handleCheckBoxChange(e)}
                                        type="checkbox"
                                        className="form-check-input"
                                        name="permissions"
                                        value={item.path}
                                        // value={usersObj.permissions[item.path]}
                                        id={"checkbox" + index}
                                      />
                                      <label
                                        htmlFor={"checkbox" + index}
                                        className="cr"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              )
                            )}
                          </Row> */}
                </AvForm>

                <hr />
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
      {isFits ? (
        <Lightbox
          mainSrc={userImage}
          enableZoom={false}
          imageCaption={"zakarie ahmed hashi image"}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      <ToastContainer />
    </Row>
  )
}

export default UsersForm
