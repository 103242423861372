import React, { useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  CardHeader,
  CardSubtitle,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MultiSelect } from "react-multi-select-component"
import Select from "react-select"
import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { classContext } from "../../context/classContext"

const ClassForm = () => {
  const {
    isOpen,
    editCondition,
    CustomerData,
    tog_standard,
    handleEdit,
    handleChange,
    HandleSubmitBtn,
    teachers,
    coursesData,
    classObj,
    options,
    setSelected,
    selected,
    disabled,
    load
  } = useContext(classContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Create new class</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <AvField
                      name="className"
                      type="text"
                      label="Name"
                      value={classObj.className}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Class Name"
                      errorMessage="Enter Class Name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      type="select"
                      name="courseID"
                      label="Course"
                      value={classObj.courseID}
                      onChange={e => handleChange(e)}
                      className="form-control "
                      errorMessage="please select Course "
                      validate={{ required: { value: true } }}
                    >
                      <option vale="">Select Course</option>
                      {coursesData?.map(course => (
                        <option value={course._id}>{course.courseName}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label>select class days</Label>
                    {/* <AvField
                    name="days"
                    type="text"
                    label="Class days"
                    value={classObj.days}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Class days taken"
                    errorMessage="Enter Class days taken"
                    validate={{
                      required: { value: true },
                    }}
                  /> */}
                    <MultiSelect
                      options={options}
                      value={selected}
                      required
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                    {/* <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={options[0]}
                      isClearable={true}
                      isSearchable={true}
                      name="color"
                      options={options}
                    /> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      type="select"
                      name="teacherID"
                      label="Teachers"
                      value={classObj.teacherID}
                      onChange={e => handleChange(e)}
                      className="form-control "
                      errorMessage="please select Teacher"
                      validate={{ required: { value: true } }}
                    >
                      <option vale="">Select Teacher</option>
                      {teachers?.map(teacher => (
                        <option value={teacher._id}>
                          {teacher.teacherName}
                        </option>
                      ))}
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="classShift"
                      type="select"
                      label="Class Shift"
                      value={classObj.classShift}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Phone"
                      errorMessage="Enter Phone"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select class Shift</option>
                      <option vlaue="offline">offline</option>
                      <option vlaue="online">online</option>
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="startedDate"
                      type="date"
                      value={classObj.startedDate}
                      label="Date started"
                      placeholder="Enter Date Started"
                      errorMessage="Enter Date Started"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="endDate"
                      type="date"
                      value={classObj.endDate}
                      label="End Date"
                      placeholder="End Date "
                      errorMessage="Enter End Started"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <div className="mb-3">
                    <AvField
                      name="TimeIn"
                      type="time"
                      value={classObj.TimeIn}
                      label="Time In"
                      placeholder="Enter Time In "
                      errorMessage="Enter Time In "
                      className="form-control"
                      onChange={e => handleChange(e)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <AvField
                      name="TimeOut"
                      type="time"
                      value={classObj.TimeOut}
                      label="Time Out"
                      placeholder="Enter Time Out"
                      errorMessage="Enter Time Out"
                      className="form-control"
                      onChange={e => handleChange(e)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <AvField
                      name="classStatus"
                      type="select"
                      label="Class Status"
                      value={classObj.classStatus}
                      onChange={e => handleChange(e)}
                      placeholder="Enter class Status"
                      // errorMessage="Enter class Status"
                      // validate={{
                      //   required: { value: true },
                      // }}
                    >
                      <option vlaue="active">active</option>
                      <option vlaue="pending">pending</option>
                      <option vlaue="blocked">blocked</option>
                      <option vlaue="completed">completed</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                type="submit"
                className="waves-effect waves-light"
              >
                {load ?"saving...":" Save class"}
               
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default ClassForm
