import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Button, Col, Container, Row, Spinner } from "reactstrap"
// import images
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import backGroundImage from "../../assets/images/portalloginSoftware1.png"
import bg from "../../assets/images/softwarelogo.png"

import { AvField, AvForm } from "availity-reactstrap-validation"
import jwtDecode from "jwt-decode"
import { postfunc } from "../../services/mainService"
const LoginPortal = ({ history }) => {
  const [loading, setloading] = useState(false)
  // const history = useHistory();

  const hanldeRedirect = () => {
    window.open("http://zaki.ezyro.com/?i=1", "_blank")
  }
  const handleValidSubmit = async (event, values) => {
    //  return console.log(values)
    try {
      setloading(true)
      const { data } = await postfunc("loginportal", values)
      const { status, message, token } = data

      if (status) {
        setloading(false)
        const user = jwtDecode(token)

        // localStorage.setItem("menus", user.menus?JSON.stringify(user.menus) :[]);

        if (user.userType == "student") {
          toast.success(message, {
            theme: "colored",
          })
          setTimeout(() => {
            history.push(`/portal`)
          }, 2000)
          localStorage.setItem("studentToken", token ? token : "")
          sessionStorage.setItem("studentToken", token ? token : "")
        } else {
          toast.warning("only student allowed here ", {
            theme: "dark",
          })
        }
      } else {
        toast.warning(message, {
          theme: "colored",
        })
        // notify(message, "error");

        setloading(false)
      }
    } catch (error) {
      setloading(false)
      toast.error(error.message, {
        theme: "colored",
      })
      // notify(message, "error");
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>student Portal | easy Soft</title>
        </MetaTags>
        <ToastContainer />
        {/* <div
            className="accountbg"
            style={{
              background: `url(${bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          ></div>

          <div className="wrapper-page account-page-full">
            <Card className="shadow-none">
              <div className="card-block">
                <div className="account-box">
                  <div className="card-box shadow-none p-4">
                    <div className="p-2">
                      <div className="text-center mt-4">
                        <Link to="/">
                          <img src={logoDark} height="22" alt="logo" />
                        </Link>
                      </div>

                      <h4 className="font-size-18 mt-5 text-center">
                        Welcome Back !
                    </h4>
                      <p className="text-muted text-center">
                        Sign in to continue to Veltrix.
                    </p>

                      <form className="mt-4" action="#">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="username">Username</label>
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="Enter username"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="userpassword">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                          />
                        </div>

                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                            </label>
                            </div>
                          </Col>

                          <Col sm="6" className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                          </button>
                          </Col>
                        </Row>

                        <Row className="mb-3 mt-2 mb-0">
                          <div className="col-12 mt-3">
                            <Link to="pages-recoverpw-2">
                              <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                          </div>
                        </Row>
                      </form>

                      <div className="mt-5 pt-4 text-center">
                        <p>
                          Don't have an account ?{" "}
                          <Link
                            to="pages-register-2"
                            className="fw-medium-medium text-primary"
                          >
                            {" "}
                          Signup now{" "}
                          </Link>{" "}
                        </p>
                        <p>
                          © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div> */}

        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col md={7}>
              <div className="authentication-bg">
                <div>
                  {/* className="bg-overlay" */}
                  <img
                    src={backGroundImage}
                    className="authentication-bg"
                  ></img>
                </div>
              </div>
            </Col>
            <Col md={2}></Col>
            <Col md={3}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center ">
                          <div>
                            <picture>
                              <img
                                src={bg}
                                height="60"
                                width="200"
                                alt="logo"
                              />
                            </picture>
                          </div>

                          <h4 className="font-size-18 mt-2">Welcome Back !</h4>
                          <p className="text-muted">exam portal.</p>
                        </div>

                        <div className="p-2 ">
                          <AvForm
                            className="mt-2"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v)
                            }}
                          >
                            <div className="mb-3">
                              <label className="form-label" htmlFor="username">
                                Student ID
                              </label>
                              <AvField
                                name="stdID"
                                type="stdID"
                                className="form-control"
                                id="username"
                                placeholder="Enter Student ID"
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="userpassword"
                              >
                                Password
                              </label>
                              <AvField
                                type="password"
                                className="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                name="password"
                                required
                              />
                            </div>

                            <Row className="mb-3">
                              <Col sm="12" className="text-end">
                                <Button
                                  className="text-white form-control w-md waves-effect waves-light"
                                  type="submit"
                                  style={{ backgroundColor: "#14213D" }}
                                >
                                  {loading ? (
                                    <div>
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                        }}
                                      ></Spinner>
                                    </div>
                                  ) : (
                                    "Log In"
                                  )}
                                </Button>
                              </Col>
                            </Row>

                            <Row className="mb-3 mt-2 mb-0">
                              <div className="col-12 mt-3">
                                <Link to="/forgot-password">
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                              </div>
                            </Row>
                          </AvForm>
                        </div>

                        <div className=" text-center">
                          <p>
                            Developed by 🙋‍♂️
                            <Link> Eng zaki ahmed</Link>
                          </p>
                          <p>All rights are reserved 2023</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginPortal
