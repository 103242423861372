import React, { createContext, useState, useEffect } from "react"
import {
    Col,
    Container,
    Button,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    ModalFooter,
    Card,
    CardBody,
    CardTitle,
  } from "reactstrap"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../../services/mainService"
import { MDBDataTable } from "mdbreact"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"

const Statements = () => {
    const [statementObj, setstatementObj] = useState({
        startDate: "",
        endDate: "",
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [statementData, setstatementData] = useState([])
  const [statementTempData, setstatementTempData] = useState([])

  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  
  useEffect(() => {
    const getReceipt = async () => {
      try {
        const { data: receiptList } = await GetAll("transection",headers)
        let checkTransection=Array.isArray(receiptList)
        
        if(checkTransection){

     
        let balalance = 0;
       
       
       let statementData = receiptList?.map((item) => {
          let obj = JSON.parse(JSON.stringify(item));
          obj.transectionType == "income" ? (balalance += obj.amount) : (balalance -= obj.amount);
         obj.runningBalance =balalance;
       
        //   let newObject = {...obj, ...obj.customerID };
          return obj;
        });
        
        
        setstatementTempData(statementData)
        setstatementData(statementData)
      }
      } catch (error) {
          console.log(error.message);
        
      }
    };
    getReceipt();

  }, []);

  const notify = (message, type) => toast[type](message);
 
  const HandleSubmitBtn = async e => {
    try {
        let start = moment(statementObj.startDate).format("YYYY-MM-DD");
        let end = moment(statementObj.endDate).format("YYYY-MM-DD");

        let result = statementTempData?.filter((item) => {
          let date = moment(item.date).format("YYYY-MM-DD");
          return date >= start && date <= end;
        });
        setstatementData(result)
        

    } catch (error) {
      console.log(error.response)
      
    }
  }
  const handleChange = e => {
    setstatementObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = statementData?.map((cust) => {
    const data = { ...cust };
    data.date = moment(data.date).format("lll")
      data.Amount="$" + data.amount

      const obj={ 
        "No":data.transectionNo,
        "Transection Type":data.transectionType,
        "Category":data.type,
        "Name":data.name,
        "Amount ":"$"+data.Amount,
        "description":data.description,
        "Balance":"$"+data.runningBalance,
        "date":data.date,
       }

    return obj;
  });
  const data = {
    columns: [
        {
            label: "No",
            field: "transectionNo",
            sort: "asc",
            width: 50,
          },
      {
        label: "transectionType",
        field: "transectionType",
        sort: "asc",
        width: 270,
      },
      {
        label: " Income Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "name",
        field: "name",
        sort: "asc",
        width: 270,
      },
     
      {
        label: "amount",
        field: "Amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "description",
        field: "description",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 100,
      },

   
    ],
    rows: statementData?.map(hos => {
      const data = { ...hos }     
      data.date = moment(data.date).format("lll")
      data.Amount="$" + data.amount
      data.balance="$" + data.runningBalance
      data.userName = data.userID?.name;

      if(data.transectionType=="income"){
        data.transectionType = (
            <div className="badge bg-success font-size-15 badge-pill mr-2">
              {data.transectionType}
            </div>
          );
      }
      else {
        data.transectionType = (
          <div className="badge bg-warning font-size-15 badge-pill mr-2">
            {data.transectionType}
          </div>
        );

      }
      
      return data
    }),
  }
  
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
      <div className="page-title-box">
            <Breadcrumbs maintitle="easy soft" title="reports" breadcrumbItem="statements" />
            <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
          <AvForm
          className="needs-validation"
          onValidSubmit={HandleSubmitBtn}
        >  

            <Row className=" mb-3">
          
              <Col md={4}>
              <div className="mb-3">
                <FormGroup className="form-group">
                   <AvField
                      type="date"
                      name="startDate"
                      label="date started"
                      value={statementObj.startDate}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      errorMessage="please select start date"
                      validate={{ required: { value: true } }}
                    >
                  
                      
                    </AvField>
                    </FormGroup>
                  
                </div>
              </Col>

              <Col md={4}>
              <div className="mb-3">
              <FormGroup className="form-group">
                   <AvField
                      type="date"
                      name="endDate"
                      label=" Ended Date "
                      value={statementObj.endDate}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      errorMessage="please end Date "
                      validate={{ required: { value: true } }}
                    >
                  
                      
                    </AvField>
                    </FormGroup>
                  
                </div>
              </Col>
              <Col md={2}>
              <div className="mb-3">
                    <Label>check out</Label>
                <FormGroup className="form-group">
                <Button
                    type="submit"
                   
                    style={{ background: "#0A928C", color: "white" }}
                    className=" dropdown-toggle waves-effect waves-light "
                  >
                    <i className="ion ion-md-add me-2"></i> check statement
                  </Button>
                    </FormGroup>
                  
                </div>
              </Col>
              <Col md={2}>
              <div className="mb-3">
                    <Label>Download List</Label>
                <FormGroup className="form-group">
                <CSVLink
                    filename={"TableContent.csv"}
                    data={exportData}
                    className="btn btn-success"
                  >
                    Download Excel
                  </CSVLink> 
                    </FormGroup>
                  
                </div>
              </Col>
            
            </Row>
            </AvForm>

            <Row>

            <MDBDataTable responsive striped bordered data={data} />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
          </div>
   

    </Container>
      </div>
    </React.Fragment>
  )


}

export default Statements
