import React, { useState, createContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container } from "reactstrap"
import moment from "moment"

import "../pages/Dashboard/datatables.scss"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import useSWRMutation from "swr/mutation"
import axios from "axios"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import jwtDecode from "jwt-decode"
import Switch from "react-switch"

import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  blockUsers,
} from "../services/mainService"
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
export const usersContext = createContext()
usersContext.displayName = "usersContext"

const UsersContextProvider = props => {
  const [isFits, setisFits] = useState(false)
  const [userImage, setuserImage] = useState()
  const [usersObj, setusersObj] = useState({
    name: "",
    phone: "",
    username: "",
    userType: "",
    // permissions:[] ,
    password: "",
    employeeID: "",
  })

  const [editCondition, setEditCondition] = useState(false)
  const [collapse, setcollapse] = useState(false)
  const [Icon, setIcon] = useState({
    iconPlusOrminus: "btn btn-info float-right feather icon-plus",
  })
  const [isOpen, setIsOpen] = useState(false)
  const [userBlockData, setuserBlockData] = useState({})
  const [SweetAlertState, setSweetAlertState] = useState({
    id: null,
    state: false,
    deleted: false,
  })
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [roles, setroles] = useState([])
  const [showpassword, setshowpassword] = useState(false)
  const [showicon, setshowicon] = useState("mdi mdi-eye")
  const [employee, setemployee] = useState([])
  const [currentBrand, setcurrentBrand] = useState("")
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }

    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  let { data: UsersList } = useSwr("users", fetchAll)
  let Users = []

  let chechArray = Array.isArray(UsersList)
  if (chechArray == true) {
    Users = UsersList
  }
  useEffect(() => {
    const load = async () => {
      const { data } = await GetAll("roles", headers)
      let checkroles = Array.isArray(data)
      // console.log(data)
      if (checkroles) {
        setroles(data)
      }
      const { data: employeeList } = await GetAll("employee", headers)

      let checkemployee = Array.isArray(employeeList)
      if (checkemployee) {
        setemployee(employeeList)
      }
      const brand = sessionStorage.getItem("brandType")
      setcurrentBrand(brand)
    }
    load()
  }, [])
  const handleshow = () => {
    if (showpassword) {
      setshowicon("mdi mdi-eye")
    } else {
      setshowicon("mdi mdi-eye-off")
    }
    setshowpassword(!showpassword)
  }
  // console.log(roles)
  const tog_standard = () => {
    setIsOpen(!isOpen)
  }
  const HandleSubmitBtn = async e => {
    try {
      //   e.preventDefault();
      setdisabled(true)
      setload(true)

      if (currentBrand == "academy") {
        delete usersObj.employeeID
      }

      if (editCondition) {
        // return console.log("usersObj",usersObj)
        const { data } = await putfunc("users", usersObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("users", fetchAll)
          toast.success(message, {
            theme: "colored",
          })
          setIsOpen(false)
          setcollapse(false)
          setusersObj({
            name: "",
            phone: "",
            username: "",
            password: "",
            userType: "",
            permissions: [],
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // return  console.log("usersObj",usersObj)

        const { data } = await postfunc("users", usersObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          toast.success(message, {
            theme: "colored",
          })
          mutate("users", fetchAll)
          setusersObj({
            name: "",
            phone: "",
            username: "",
            password: "",
            userType: "",
            permissions: [],
          })
          // notify(message, "success");
          setIsOpen(false)
          setcollapse(false)
        } else {
          toast.warning(message, {
            theme: "colored",
          })
          setdisabled(false)
          setload(false)
          // notify(message, "warning");
        }
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      })
      setdisabled(false)
      setload(false)
    }
  }
  const handleChange = e => {
    // console.log(e.target.name + " ", e.target.value);
    setusersObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    console.log("data", data)
    data.date = new Date(data.date)
    setEditCondition(true)
    setusersObj(data)
    setcollapse(true)

    window.scrollTo(0, 200)
  }
  const handleCheckBoxChange = e => {
    console.log(e.target.value)
    if (e.target.checked) {
      setusersObj(prev => {
        let new_permission = [...prev.permissions, e.target.value]
        console.log("new_permission", new_permission)
        return {
          ...prev,
          [e.target.name]: e.target.value,
          permissions: new_permission,
        }
      })
    } else {
      setusersObj(prev => {
        let new_permission = prev.permissions.filter(p => p !== e.target.value)
        return {
          ...prev,
          [e.target.name]: e.target.value,
          permissions: new_permission,
        }
      })
    }
  }
  const collapseToggle = () => {
    setcollapse(!collapse)
    if (collapse) {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-plus",
      })
    } else {
      setIcon({
        iconPlusOrminus: "btn btn-info float-right feather icon-minus",
      })
    }
  }
  const btnCancelTogle = () => {
    setcollapse(!collapse)
    setIcon({ iconPlusOrminus: "btn btn-info float-right feather icon-plus" })
  }
  const handlechangeImage = e => {
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      setuserImage(e.target.files[0])
    }
  }
  let userPermessions = [
    {
      name: "Dashboard",
      icon: "ti-home",
      path: "/dashboard",
    },
    {
      name: "CRM",
      icon: "ti-bookmark-alt",
      path: "",
      subMenu: [
        {
          name: "Customers",
          path: "/customers",
        },
      ],
    },
    {
      name: "HRM",
      icon: "ti-package",
      path: "",
      subMenu: [
        {
          name: "Enployee",
          path: "/employee",
        },
      ],
    },

    {
      name: "Users",
      icon: "fas fa-user",
      path: "",
      subMenu: [
        {
          name: "Users",
          path: "/users",
        },
        {
          name: "Roles",
          path: "/roles",
        },
        {
          name: "Activity",
          path: "/activity",
        },
      ],
    },

    {
      name: "Reports",
      icon: "ti-view-grid",
      path: "",
      subMenu: [
        {
          name: "Customer Report",
          path: "/customerReport",
        },
        {
          name: "Users Report",
          path: "/usersReport",
        },
      ],
    },
  ]

  const location = useLocation()
  Auhtentication(location.pathname)

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const handleBlock = userdata => {
    const data = { ...userdata }
    setSweetAlertState({
      id: data._id,
      state: true,
      deleted: true,
    })

    if (data.status == true) {
      data.currentstatus = false
    } else if (data.status == false) {
      data.currentstatus = true
    }

    setuserBlockData(data)
    // console.log("currentstatus",data.currentstatus)
  }

  return (
    <usersContext.Provider
      value={{
        collapse,
        usersObj,
        isOpen,
        editCondition,
        tog_standard,
        HandleSubmitBtn,
        handleChange,
        handleEdit,
        handleCheckBoxChange,
        collapseToggle,
        btnCancelTogle,
        userPermessions,
        Users,
        setuserImage,
        setusersObj,
        handlechangeImage,
        userImage,
        setisFits,
        isFits,
        handleBlock,
        OnSymbol,
        Offsymbol,
        SweetAlertState,
        setSweetAlertState,
        userBlockData,
        blockUsers,
        disabled,
        load,
        roles,
        handleshow,
        showpassword,
        showicon,
        employee,
        currentBrand,
      }}
    >
      {props.children}
    </usersContext.Provider>
  )
}

export default UsersContextProvider
