import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

import { useReactToPrint } from "react-to-print"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import dayjs from "dayjs"
import jwtDecode from "jwt-decode"
import { Field, FieldArray, Formik } from "formik"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"
// import logoSm from "../../assets/images/logo-sm.png";
import headerImage from "../../assets/images/softwarelogo.png"

import moment from "moment"

const CustomInvoice = () => {
  const [currentUser, setcurrentUser] = useState({})
  const [sq1, setsq1] = useState(true)
  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(25)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [collapse, setcollapse] = useState(false)
  const [isEdit, setisEdit] = useState(false)
  const [drugs, setdrugs] = useState([])
  const [patient, setpatient] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [loading, setloading] = useState(false)
  const [printData, setprintData] = useState({})
  const [refresh, setrefresh] = useState(0)

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // return console.log(currentUser)

      // return
      // if (activeTabProgress == 3) {
      ;(values.date = moment(date).format("YYYY-MM-DD[T]HH:mm:ss")),
        (values.phoneReceipt = 615432167),
        (values.userID = sessionStorage.getItem("userID")),
        (values.isReceipt = sq1)

      if (values.isReceipt == false && values.customerID == "") {
        return toast.error(
          "dept not allowed unkown person , so you have to paid money"
        )
      }

      if (
        values.isReceipt == true &&
        values.customerID == "" &&
        Number(values.amountPaid) != Number(values.total)
      ) {
        return toast.error(
          `amount paid is required and have to paid ${values.total}`
        )
      }

      if (values.isReceipt == true && values.amountPaid == 0) {
        return toast.error(`amount paid is required`)
      }

      if (values.note == "") {
        delete values.note
      }
      if (values.customerID == "") {
        delete values.customerID
      }
      if (values.phonePaid == "") {
        delete values.phonePaid
      }

      if (values.paymentMethod == "") {
        delete values.paymentMethod
      }
      values.userID = currentUser._id
      // return console.log("values",values)

      setloading(true)
      //  return
      setdisabled(true)
      const { data } = await axios.post(
        url + "/invoice/customInvoice",
        values,
        headers
      )
      const { status, message, receiptData, currentinvoice } = { ...data }
      if (status) {
        // mutate("invoice", fetchAll)
        // console.log("savedReceipt",savedReceipt)
        let receiptObj = {
          receiptData,
          currentinvoice,
        }
        setprintData(receiptObj)
        receiptObj.receiptData.datePaid = moment(
          data.receiptData.datePaid
        ).format("lll")
        if (values.isReceipt == true) {
          setactiveTab(4)
        }

        toast.success(message)
        // setIsOpen(false)
        setcollapse(false)
        resetForm()
        setdisabled(false)
        setloading(false)
      } else {
        toast.error(message)
        setdisabled(false)
        setloading(false)
      }
      // }
    } catch (error) {
      toast.error(error.message)
      setdisabled(false)
      setloading(false)
    }
  }
  const date = new Date()

  useEffect(() => {
    const load = async () => {
      try {
        const { data: patientList } = await axios.get(
          `${url}/customers`,
          headers
        )
        // const { data:LabtestList } = await axios.get(`${url}/labLatest`,headers)
        const { data: stockItemsList } = await axios.get(
          `${url}/stock`,
          headers
        )

        if (Array.isArray(stockItemsList)) {
          setdrugs(stockItemsList)
        }
        if (Array.isArray(patientList)) {
          setpatient(patientList)
        }
        const token = localStorage.getItem("token")
        if (token) {
          const user = jwtDecode(token)
          setcurrentUser(user)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    load()
  }, [refresh])
  const initialValues = {
    customerID: "",
    discount: 0,

    balance: 0,
    paymentMethod: "",
    phonePaid: "",
    amountPaid: 0,
    total: 0,

    items: [
      {
        itemID: "",
        Quantity: 1,
        Price: 0,
        Amount: 0,
      },
    ],
  }

  function toggleTab(tab, values, setFieldValue) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        console.log("values", values)
        if (tab === 2) {
          setFieldValue(
            `total`,
            values.items.reduce(
              (prev, item) => Number(item.Price) * Number(item.Quantity) + prev,
              0
            ) - values.discount ?? 0
          )
        }
        // if(tab==3){
        //   handleSubmit(values)
        // }
        setactiveTab(tab)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          setprogressValue(25)
        }
        if (tab === 2) {
          setprogressValue(50)
        }
        if (tab === 3) {
          setprogressValue(75)
        }
        if (tab === 4) {
          setprogressValue(100)
        }
      }
    }
  }
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "receipt",
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>invoice | Software Decoration</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Software Decoration"
            title="Sales"
            breadcrumbItem=""
          />

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4 d-print-none">Basic Wizard</h4>
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix d-print-none">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            // className="bg-success text-white"
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="number">1.</span> Generate Invoice
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="number">2.</span> Receipt
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                          >
                            <span className="number">3.</span>
                            Confirm Detail
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                          >
                            <span className="number">4.</span>
                            Print Receipt
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <ToastContainer />

                      {/* <CardTitle className="mb-4">Invoice</CardTitle> */}
                      <Formik
                        enableReinitialize={true}
                        initialValues={
                          isEdit
                            ? {
                                id: invoiceObj._id,
                                customerID: invoiceObj.customerID?._id,
                                invoiceNumber: invoiceObj.invoiceNumber,
                                discount: invoiceObj.discount,
                                subTotal: invoiceObj.subTotal,
                                Total: invoiceObj.Total,
                                note: invoiceObj.note,

                                items: invoiceObj.items?.map(item => ({
                                  id: item._id,
                                  itemID: item.itemID._id,
                                  Quantity: item.Quantity,
                                  Price: item.Price,
                                  Amount: item.Quantity * item.Price,
                                })),
                              }
                            : initialValues
                        }
                        // validationSchema={invoiceSchema}
                        onSubmit={handleSubmit}
                      >
                        {({
                          submitForm,
                          values,
                          setFieldValue,
                          errors,
                          touched,
                        }) => (
                          <div>
                            <TabContent activeTab={activeTab} className="body">
                              <TabPane tabId={1} className="mt-4">
                                <Row className="mb-4">
                                  <Col lg={12} className="mb-3">
                                    <FormGroup>
                                      <Label>Customer Name </Label>
                                      <Select
                                        name="customerID"
                                        placeholder="select customer"
                                        required
                                        onChange={event => {
                                          setFieldValue(
                                            `customerID`,
                                            event.value
                                          )
                                        }}
                                        options={patient?.map(
                                          (patient, index) => {
                                            return {
                                              label:
                                                patient.name +
                                                "-" +
                                                patient.phone,
                                              value: patient._id,
                                              key: index,
                                            }
                                          }
                                        )}
                                      ></Select>
                                    </FormGroup>

                                    {errors.customerID && touched.customerID ? (
                                      <span className="text-danger">
                                        {errors.customerID}
                                      </span>
                                    ) : null}
                                  </Col>
                                </Row>

                                <div className=" px-3 bg-light mb-4 rounded-top">
                                  <div className="row text-white bg-primary py-2 mb-4 rounded-top">
                                    <div className="col-lg-3 ">Item Name</div>{" "}
                                    <div className="col-md-2 ">Price</div>{" "}
                                    <div className="col-md-3 ">Quantity</div>
                                    <div className="col-md-1 ">Amount</div>
                                  </div>

                                  <FieldArray name="items">
                                    {({ insert, remove, push }) => (
                                      <div>
                                        {values.items.length > 0 &&
                                          values.items?.map((item, index) => (
                                            <>
                                              <Row key={index}>
                                                <Col lg={3} className="mb-3">
                                                  <Label>select Item</Label>
                                                  {isEdit ? (
                                                    <Field
                                                      as="select"
                                                      name={`items.${index}.itemID`}
                                                      required
                                                      className="form-control"
                                                      placeholder="Enter Your Name"
                                                      onChange={event => {
                                                        let currentDrugs =
                                                          drugs?.find(
                                                            drug =>
                                                              drug._id ==
                                                              event.target.value
                                                          )
                                                        //   console.log("currentDrugs",currentDrugs)
                                                        setFieldValue(
                                                          `items.${index}.Price`,
                                                          currentDrugs.Amount ??
                                                            0
                                                        )
                                                        setFieldValue(
                                                          `items.${index}.itemID`,
                                                          event.target.value
                                                        )
                                                        setFieldValue(
                                                          `items.${index}.Quantity`,
                                                          1
                                                        )
                                                        // setFieldValue(
                                                        //   `subTotal`,10
                                                        // );
                                                      }}
                                                    >
                                                      <option value="">
                                                        Choose Drug
                                                      </option>
                                                      {drugs?.map(drug => (
                                                        <option
                                                          value={drug._id}
                                                        >
                                                          {drug.itemName}
                                                        </option>
                                                      ))}
                                                    </Field>
                                                  ) : (
                                                    <Select
                                                      placeholder="select itemID "
                                                      required
                                                      name={`items.${index}.itemID`}
                                                      onChange={event => {
                                                        let DrugSelected =
                                                          drugs.find(
                                                            drug =>
                                                              drug._id ==
                                                              event.value
                                                          )
                                                        console.log(
                                                          "DrugSelected",
                                                          DrugSelected
                                                        )

                                                        setFieldValue(
                                                          `items.${index}.Price`,
                                                          DrugSelected.Amount ??
                                                            0
                                                        )
                                                        setFieldValue(
                                                          `items.${index}.itemID`,
                                                          event.value
                                                        )
                                                        setFieldValue(
                                                          `items.${index}.Quantity`,
                                                          1
                                                        )

                                                        // if(currentDrug.quantity==0){
                                                        //   toast.warning(`${currentDrug.itemName} out of the stock`)
                                                        //   // alert()
                                                        // }

                                                        // setFieldValue(`items.${index}.itemID`, event.value)
                                                      }}
                                                      options={drugs?.map(
                                                        (drug, index) => {
                                                          return {
                                                            label:
                                                              drug.itemName,
                                                            value: drug._id,
                                                            key: index,
                                                          }
                                                        }
                                                      )}
                                                    ></Select>
                                                  )}
                                                </Col>

                                                <Col lg={2} className="mb-3">
                                                  <Label>Price</Label>
                                                  <Field
                                                    // disabled

                                                    required
                                                    name={`items.${index}.Price`}
                                                    // value={gerserviceprice(item.itemID)}
                                                    className="form-control"
                                                    placeholder="Enter Price"
                                                    type="text"
                                                  />
                                                </Col>
                                                <Col lg={2} className="mb-3">
                                                  <Label>Qountity</Label>
                                                  <Field
                                                    required
                                                    minLength="1"
                                                    name={`items.${index}.Quantity`}
                                                    className="form-control"
                                                    placeholder="Enter quantity"
                                                    type="number"
                                                  />
                                                </Col>

                                                <Col lg={2} className="mb-3">
                                                  <Label>Amount</Label>
                                                  <Field
                                                    name={`items.${index}.Amount`}
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    type="number"
                                                    value={
                                                      Number(item.Quantity) *
                                                      Number(item.Price)
                                                    }
                                                    disabled
                                                  />
                                                </Col>

                                                <Col
                                                  lg={3}
                                                  className="mb-3 mt-4"
                                                >
                                                  <button
                                                    type="button"
                                                    color="danger"
                                                    className="dripicons-trash btn btn-danger"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <i className=" ri-delete-bin-line"></i>
                                                  </button>

                                                  <button
                                                    type="button"
                                                    className="btn btn-dark mx-2"
                                                    onClick={() =>
                                                      push({
                                                        itemID: "",
                                                        Quantity: 1,
                                                        Price: 0,
                                                        Amount: 0,
                                                      })
                                                    }
                                                  >
                                                    Add item
                                                  </button>
                                                </Col>
                                              </Row>
                                              <hr />
                                            </>
                                          ))}
                                      </div>
                                    )}
                                  </FieldArray>
                                </div>

                                <Row className="row justify-content-end mb-warning">
                                  <Col className="col-lg-6 col-xl-5">
                                    <div className="text-size-16 border-bottom mb-2">
                                      <div className="d-flex align-items-center justify-content-between py-2">
                                        <p class="text-muted mb-0">Sub Total</p>
                                        <Field
                                          type="hidden"
                                          name="subTotal"
                                          value={values.items?.reduce(
                                            (prev, item) =>
                                              Number(item.Price) *
                                                Number(item.Quantity) +
                                              prev,
                                            0
                                          )}
                                          class="form-control "
                                        ></Field>
                                        <h6 class="mb-0">
                                          $
                                          {values.items?.reduce(
                                            (prev, item) =>
                                              Number(item.Price) *
                                                item.Quantity +
                                              prev,
                                            0
                                          )}
                                        </h6>
                                      </div>

                                      <div className="row d-flex align-items-center justify-content-between py-2">
                                        <div className="col-7">
                                          <p class="text-muted mb-0">
                                            Discount{" "}
                                          </p>
                                        </div>
                                        <div className="col-5">
                                          <Field
                                            type="number"
                                            name="discount"
                                            required
                                            max={values.items.reduce(
                                              (prev, item) =>
                                                item.Price * item.Quantity +
                                                prev,
                                              0
                                            )}
                                            class="form-control "
                                          ></Field>
                                        </div>
                                      </div>

                                      {/* main Total div */}
                                      <div className="d-flex align-items-center justify-content-between py-2">
                                        <p class="text-muted mb-0">
                                          Grand Total
                                        </p>
                                        <Field
                                          type="hidden"
                                          name="Total"
                                          value={
                                            values.items.reduce(
                                              (prev, item) =>
                                                parseFloat(item.Price) *
                                                  parseFloat(item.Quantity) +
                                                prev,
                                              0
                                            ) - parseFloat(values.discount)
                                          }
                                          class="form-control "
                                        ></Field>
                                        <h4 class="mb-0">
                                          $
                                          {values.items
                                            .reduce(
                                              (prev, item) =>
                                                parseFloat(item.Price) *
                                                  parseFloat(item.Quantity) +
                                                prev,
                                              0
                                            )
                                            .toFixed(2) -
                                            parseFloat(values.discount)}
                                        </h4>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={12} className="mb-3">
                                    <label htmlFor="quantity">
                                      {" "}
                                      Note (optional)
                                    </label>
                                    <Field
                                      as="textarea"
                                      name="note"
                                      className="form-control"
                                      placeholder="Enter your note"
                                      // value={invoiceObj.invoiceNumber}
                                      // onChange={(e) => handleChangeInput(e)}
                                      validate={{
                                        required: { value: true },
                                        minLength: { value: 10 },
                                      }}
                                    />
                                    {errors.note && touched.note ? (
                                      <span className="text-danger">
                                        {errors.note}
                                      </span>
                                    ) : null}
                                  </Col>
                                </Row>
                              </TabPane>

                              <TabPane tabId={2} className="mt-4">
                                <Label> Receipt or Due </Label>
                                <div className="square-switch">
                                  <input
                                    type="checkbox"
                                    id="square-switch3"
                                    switch="bool"
                                    checked={sq1}
                                    onChange={event => {
                                      // console.log(event.target.checked);
                                      setsq1(!sq1)
                                    }}
                                  />
                                  <label
                                    htmlFor="square-switch3"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                  />
                                </div>
                                {sq1 ? (
                                  <div>
                                    <Row className="mb-4">
                                      <Col lg={4} className="mb-3">
                                        <FormGroup>
                                          <Label>Total </Label>
                                          <Field
                                            name="total"
                                            className="form-control"
                                            type="number"
                                            disabled
                                            placeholder=" total"
                                            required
                                          ></Field>
                                        </FormGroup>

                                        {errors.customerID && touched.total ? (
                                          <span className="text-danger">
                                            {errors.total}
                                          </span>
                                        ) : null}
                                      </Col>

                                      <Col lg={4} className="mb-3">
                                        <FormGroup>
                                          <Label>Amount Paid</Label>
                                          <Field
                                            name="amountPaid"
                                            className="form-control"
                                            type="number"
                                            onChange={event => {
                                              console.log("values", values)

                                              setFieldValue(
                                                `amountPaid`,
                                                event.target.value
                                              )
                                              setFieldValue(
                                                `balance`,
                                                values.total -
                                                  event.target.value
                                              )
                                            }}
                                            placeholder=" Amount "
                                            required
                                          ></Field>
                                        </FormGroup>

                                        {errors.customerID &&
                                        touched.customerID ? (
                                          <span className="text-danger">
                                            {errors.customerID}
                                          </span>
                                        ) : null}
                                      </Col>
                                      <Col lg={4} className="mb-3">
                                        <FormGroup>
                                          <Label>Phone paid (optional)</Label>
                                          <Field
                                            name="phonePaid"
                                            className="form-control"
                                            type="number"
                                            placeholder=" phone Paid"
                                            required
                                          ></Field>
                                        </FormGroup>

                                        {errors.phonePaid &&
                                        touched.phonePaid ? (
                                          <span className="text-danger">
                                            {errors.phonePaid}
                                          </span>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col lg={6} className="mb-3">
                                        <FormGroup>
                                          <Label>payment method</Label>
                                          <Field
                                            name="paymentMethod"
                                            className="form-control"
                                            as="select"
                                            placeholder="payment method"
                                            required
                                          >
                                            <option values="Evc plus">
                                              Evc plus
                                            </option>
                                            <option values="Edahab">
                                              Edahab
                                            </option>
                                            <option values="jeeb">jeeb</option>
                                            <option values="cash">cash</option>
                                            <option values="others">
                                              others
                                            </option>
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                      <Col lg={6} className="mb-3">
                                        <FormGroup>
                                          <Label>Balance</Label>
                                          <Field
                                            name="balance"
                                            className="form-control"
                                            type="number"
                                            disabled
                                            placeholder="balance"
                                            required
                                          ></Field>
                                        </FormGroup>

                                        {errors.phoneReceipt &&
                                        touched.phoneReceipt ? (
                                          <span className="text-danger">
                                            {errors.phoneReceipt}
                                          </span>
                                        ) : null}
                                      </Col>
                                    </Row>
                                  </div>
                                ) : null}
                              </TabPane>

                              <TabPane tabId={3} className="mt-4">
                                <div className="row justify-content-center">
                                  <Col lg="6">
                                    <div className="text-center">
                                      <div className="mb-4">
                                        {/* <i className="mdi mdi-check-circle-outline text-success display-4" /> */}
                                      </div>
                                      {loading ? (
                                        <Spinner
                                          className=""
                                          style={{
                                            color: "#028B82",
                                            width: "4rem",
                                            height: "4rem",
                                          }}
                                        />
                                      ) : (
                                        <div>
                                          <h5>Confirm Detail</h5>
                                          <p className="text-muted">
                                            before you submit check every thing
                                            is correct .
                                          </p>
                                        </div>
                                      )}
                                      <div>
                                        <Button
                                          color="Success"
                                          type="submit"
                                          disabled={disabled}
                                          className="btn btn-success d-print-none mt-4"
                                          // onClick={()=>handleSubmit(values)}
                                          onClick={submitForm}
                                        >
                                          Submit order
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                              </TabPane>

                              <TabPane tabId={4} className="mt-4">
                                <div id="printablediv" ref={componentRef}>
                                  <Row className="mt-4">
                                    <Col md={1}></Col>
                                    <Col md={9}>
                                      <Row>
                                        <div className="col-12">
                                          <Row className="invoice-title">
                                            <Col md={6}>
                                              <h3 className="mt-0">
                                                <img
                                                  src={headerImage}
                                                  alt="logo"
                                                  height="44"
                                                />
                                              </h3>
                                            </Col>
                                            <Col md={6}>
                                              <h4 className="float-end font-size-16">
                                                <strong>
                                                  RecNo #{" "}
                                                  {
                                                    printData?.receiptData
                                                      ?.receiptNo
                                                  }
                                                </strong>
                                              </h4>
                                            </Col>
                                          </Row>
                                          <hr />
                                          <Row>
                                            <Col md={6}>
                                              <address>
                                                <strong>
                                                  Softiweer Decoration :
                                                </strong>
                                                <br />
                                                Digital Sign
                                                <br />
                                                howlwadaag,mogdishu,somalia
                                                <br />
                                                252614886697
                                                <br />
                                                Wadnaha Streat
                                              </address>
                                            </Col>
                                            <Col md={6} className=" text-end">
                                              <address>
                                                <strong>Billed To:</strong>
                                                <br />
                                                Name:{" "}
                                                {
                                                  printData?.receiptData
                                                    ?.customerID?.name
                                                }
                                                <br />
                                                Phone:
                                                {
                                                  printData?.receiptData
                                                    ?.customerID?.phone
                                                }
                                                <br />
                                                {
                                                  printData?.receiptData
                                                    ?.customerID?.gender
                                                }
                                                <br />
                                                {/* Springfield, ST 54321 */}
                                              </address>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <div className="col-6 mt-4">
                                              <address>
                                                <strong>Payment Method:</strong>
                                                <br />
                                                {
                                                  printData?.receiptData
                                                    ?.paymentMethod
                                                }
                                                <br />
                                              </address>
                                            </div>
                                            <div className="col-6 mt-4 text-end">
                                              <address>
                                                <strong> Date:</strong>
                                                <br />
                                                {
                                                  printData?.receiptData
                                                    ?.datePaid
                                                }
                                                <br />
                                                <br />
                                              </address>
                                            </div>
                                          </Row>
                                        </div>
                                      </Row>

                                      <Row>
                                        {/* <div className="col-12"> */}
                                        <div>
                                          <div className="p-2"></div>
                                          <div className="">
                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <td>
                                                      <strong>InvNo</strong>
                                                    </td>
                                                    <td>
                                                      <strong>RecNo</strong>
                                                    </td>
                                                    <td>
                                                      <strong>
                                                        Amount Paid
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      Inv#{" "}
                                                      {
                                                        printData?.receiptData
                                                          ?.invoiceID
                                                          ?.invoiceNumber
                                                      }
                                                    </td>
                                                    <td>
                                                      RNo#{" "}
                                                      {
                                                        printData?.receiptData
                                                          ?.receiptNo
                                                      }
                                                    </td>
                                                    <td>
                                                      $
                                                      {
                                                        printData?.receiptData
                                                          ?.amountPaid
                                                      }
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>

                                            <div className="d-print-none">
                                              <div className="float-end">
                                                <Button
                                                  type="button"
                                                  onClick={handlePrint}
                                                  className="btn btn-success waves-effect waves-light"
                                                >
                                                  <i className="me-2 fa fa-print"></i>{" "}
                                                  print
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* </div> */}
                                      </Row>
                                    </Col>

                                    <Col md={2}></Col>
                                  </Row>
                                </div>
                              </TabPane>
                            </TabContent>

                            <div className="actions clearfix d-print-none mt-4">
                              <ul>
                                <li
                                  className={
                                    activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    color="primary"
                                    className="btn btn-primary d-print-none"
                                    onClick={() => {
                                      toggleTab(
                                        activeTab - 1,
                                        values,
                                        setFieldValue
                                      )
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li
                                  className={
                                    activeTab === 4 ? "next disabled" : "next"
                                  }
                                >
                                  <Link
                                    color="primary"
                                    className="btn btn-primary d-print-none"
                                    // type="submit"
                                    onClick={() => {
                                      toggleTab(
                                        activeTab + 1,
                                        values,
                                        setFieldValue
                                      )
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomInvoice
