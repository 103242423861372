import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import { useContext } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import { mutate } from "swr"
import { feeContext } from "../../context/feeContext"
import "../Dashboard/datatables.scss"

const FeeForm = () => {
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const {
    isOpen,
    editCondition,
    tog_standard,
    Receipt_tog,
    Showreceipt,
    handleChange,
    handleChangeReceipt,
    HandleSubmitBtn,
    HandleSubmitReceipt,
    classData,
    feeObj,
    receiptObj,
    disabled,
    load,
    setsweetAlertConfirm,
    sweetAlertConfirm,
    deletefunc,
    fetchAll,
    Students,
    StudentIDOnachnge,
    studentID,
    RadioBoxChange,
    radioBoxValue,
    classload,
  } = useContext(feeContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>
        {sweetAlertConfirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={async () => {
              try {
                const { data } = await deletefunc("fee", feeObj, headers)
                const { status, message } = { ...data }
                if (status) {
                  toast.success(message)
                  setsweetAlertConfirm(false)
                  mutate("fee", fetchAll)
                } else {
                  toast.error(message)
                }
              } catch (error) {
                toast.error(error.message)
              }
            }}
            onCancel={() => setsweetAlertConfirm(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Add fee</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <AvRadioGroup
                  inline
                  name="radioValue"
                  onChange={event => RadioBoxChange(event)}
                  // label="Select Fee Method"
                  // required
                  value={radioBoxValue}
                >
                  <AvRadio label="class" value="class" />
                  <AvRadio label="Single Student" value="single" />
                </AvRadioGroup>
              </Row>
              {radioBoxValue == "single" ? (
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Select
                        name="studentID"
                        value={studentID}
                        // isClearable={true}
                        isSearchable={true}
                        onChange={e => StudentIDOnachnge(e)}
                        options={Students?.map((student, index) => {
                          return {
                            label: student.studentName + "-" + student.stdID,
                            value: student._id,
                            key: index,
                          }
                        })}
                      ></Select>
                    </div>
                  </Col>
                  {classload ? (
                    <div>
                      <Spinner
                        className="align-center"
                        color="success"
                        animation="border"
                        role="status"
                        // style={{ width: "2rem", height: "1rem" }}
                      ></Spinner>
                    </div>
                  ) : (
                    ""
                  )}
                </Row>
              ) : null}
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="feetype"
                      type="select"
                      label="fee type"
                      value={feeObj.feetype}
                      onChange={e => handleChange(e)}
                      placeholder="Enter fee type"
                      errorMessage="Enter type"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">Select fee </option>
                      <option value="monthlyfee">monthly fee </option>
                      <option value="Registrationfee">Registration Fee </option>
                      <option value="workshop">Work shop fee </option>
                      <option value="certificate">certificate fee </option>
                      <option value="bookfee">Book fee </option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="feeName"
                      type="text"
                      label="fee Name"
                      value={feeObj.feeName}
                      onChange={e => handleChange(e)}
                      placeholder="Enter fee Name"
                      errorMessage="Enter feeName"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3">
                    <AvField
                      name="classID"
                      type="select"
                      label="Select Class"
                      value={feeObj.classID}
                      onChange={e => handleChange(e)}
                      placeholder="Select class"
                      errorMessage="Select class "
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">Select Class</option>
                      <option value="All">All Class</option>
                      {classData?.map(classes => (
                        <option value={classes._id}>{classes.className}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>

                {feeObj.feetype !== "monthlyfee" && (
                  <Col>
                    <div className="mb-3">
                      <AvField
                        name="feeAmount"
                        type="number"
                        label="Amount"
                        value={feeObj.feeAmount}
                        onChange={e => handleChange(e)}
                        placeholder="Enter fee Amount"
                        errorMessage="Enter fee Amount"
                        validate={{
                          required: { value: true },
                        }}
                      ></AvField>
                    </div>
                  </Col>
                )}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "generating..." : " Genarate Fee"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        {/* Receipt Model */}

        {/* <Modal   isOpen={Showreceipt} toggle={Receipt_tog}>
        <ModalHeader
          style={{
            background: "#14213D",
            color: "white",
            border: "#0A928C",
          }}
          toggle={Receipt_tog}
        >
          {" "}
          <p>Make Receipt</p>
        </ModalHeader>
        <AvForm
          className="needs-validation"
          onValidSubmit={HandleSubmitReceipt}
        >
          <ModalBody>
            <Row>
            <Col lg={6}>
                <div className="mb-3">
                   <AvField
                    name="phonePaid"
                    type="number"
                    label="phone Paid"
                    value={receiptObj.phonePaid}
                    onChange={(e) => handleChangeReceipt(e)}
                    placeholder="Enter phonePaid"
                    errorMessage="Enter phone Paid"
                    validate={{
                      required: { value: true },
                    }}
                  >

                    </AvField>

                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                   <AvField
                    name="phoneReceipt"
                    type="number"
                    label="Receipt phone"
                    value={receiptObj.phoneReceipt}
                    onChange={(e) => handleChangeReceipt(e)}
                    placeholder="Enter phoneReceipt"
                    errorMessage="Enter phone Paid"
                    validate={{
                      required: { value: true },
                    }}
                  >

                    </AvField>

                </div>
              </Col>


            </Row>
            <Row>
            <Col lg={6}>
                <div className="mb-3">
                   <AvField
                    name="AmountPaid"
                    type="number"
                    label="AmountPaid"
                    value={receiptObj.AmountPaid}
                    onChange={(e) => handleChangeReceipt(e)}
                    placeholder="Enter Amount Paid"
                    errorMessage="Enter Amount Paid"
                    validate={{
                      required: { value: true },
                    }}
                  >

                    </AvField>

                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                   <AvField
                    name="datePaid"
                    type="datetime-local"
                    label="date Paid"
                    value={receiptObj.datePaid}
                    onChange={(e) => handleChangeReceipt(e)}
                    placeholder="Enter date Paid"
                    errorMessage="Enter date Paid"
                    validate={{
                      required: { value: true },
                    }}
                  >

                    </AvField>

                </div>
              </Col>


            </Row>

          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="danger"
              onClick={Receipt_tog}
              className="waves-effect"
            >
              Close
            </Button>
            <Button
              type="submit"
              color="success"
              className="waves-effect waves-light"
            >
              Make Receipt
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal> */}
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default FeeForm
