import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
// availity-reactstrap-validation
import moment from "moment"
// action
import { GetById } from "../../services/mainService"
import { apiError, registerUser, registerUserFailed } from "../../store/actions"
// Redux
import jwtDecode from "jwt-decode"
import { connect } from "react-redux"
// import images
import fullLogo from "../../assets/images/softwarelogo.png"
// import { toast } from "react-toastify"
import ReactEcharts from "echarts-for-react"
import { ShimmerTitle } from "react-shimmer-effects"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const StudentComplain = props => {
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  const [currentStudent, setcurrentStudent] = useState({})
  const [chartsummary, setchartsummary] = useState({
    totalComplain: 0,
    assigned: 0,
    accepted: 0,
    process: 0,
    completed: 0,
  })
  const [showShimmer, setshowShimmer] = useState(true)

  const [studentComplains, setstudentComplains] = useState([])
  const token = localStorage.getItem("studentToken")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["assigned", "accepted", "process", "completed"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#4F192E", "#F38A0C", "#ec4561", "#02a499"],
      series: [
        {
          name: "complain",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "10",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: chartsummary.assigned, name: "assigned" },
            { value: chartsummary.accepted, name: "accepted" },
            { value: chartsummary.process, name: "process" },
            { value: chartsummary.completed, name: "completed" },
          ],
        },
      ],
    }
  }

  useEffect(() => {
    async function load() {
      try {
        const studentToken = localStorage.getItem("studentToken")
        if (studentToken) {
          let student = jwtDecode(studentToken)
          setcurrentStudent(student.studentInfo)
          setusername(student.name)
          let { data } = await GetById("complain", student.studentID, headers)
          let checkComplain = Array.isArray(data)
          if (checkComplain) {
            setstudentComplains(data)
            const statusCounts = {
              totalComplain: data.length,
              assigned: 0,
              process: 0,
              accepted: 0,
              completed: 0,
            }
            data.forEach(item => {
              if (statusCounts.hasOwnProperty(item.status)) {
                statusCounts[item.status]++
              }
            })
            setchartsummary(statusCounts)
          }
        }
        setshowShimmer(false)
      } catch (error) {
        setshowShimmer(false)
      }
    }
    load()
  }, [])

  const studentToken = localStorage.getItem("studentToken")
  const studentTokenSession = sessionStorage.getItem("studentToken")
  const history = useHistory()
  if (!studentTokenSession) {
    history.push(`/loginportal`)
  }
  const handleLogOut = () => {
    sessionStorage.removeItem("studentToken")
    localStorage.removeItem("studentToken")
    history.push(`/loginportal`)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Student Complain | easy soft</title>
      </MetaTags>
      <div style={{ backgroundColor: "#D8E9F9" }}>
        <ToastContainer />
        <div className="container">
          <Row>
            <Col className="mt-3 col-md-9">
              <img src={fullLogo} height="48" width={170} alt="logo" />
            </Col>
            <Col className="mt-3 col-md-3 " color="">
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item waves-effect pl-4"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  <span style={{ color: "#14213D" }}>{username}</span>
                  <span
                    style={{ color: "#14213D" }}
                    className="mdi mdi-chevron-down "
                  ></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end ">
                  <Link to="/portal" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewMarks</span>
                  </Link>

                  <Link to="/viewAttendence" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewAttendence</span>
                  </Link>
                  <Link to="/studentComplain" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>Complain</span>
                  </Link>

                  <div className="dropdown-divider" />
                  <Button
                    onClick={() => handleLogOut()}
                    className="dropdown-item"
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>Logout</span>
                  </Button>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>

      <div className="account-pages py-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#14213D" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Student Complain
                    </h5>
                    <p className="text-white-50">Complain</p>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div
                    class="row"
                    style={{ padding: "10px" }}
                    bis_skin_checked="1"
                  >
                    <div class="col-md-4" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 600 }}> Name: </b>
                        <span id="lbl_name">{currentStudent?.studentName}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}> Student ID: </b>
                        <span id="lbl_student_id">{currentStudent?.stdID}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Phone: </b>
                        <span id="lbl_faculty">
                          {currentStudent?.studentPhone}
                        </span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Address: </b>
                        <span id="lbl_department">
                          {currentStudent?.studentAddress}
                        </span>
                      </p>
                    </div>
                  </div>
                  <hr></hr>

                  {showShimmer ? (
                    <ShimmerTitle
                      title
                      text
                      cta
                      thumbnailWidth={370}
                      thumbnailHeight={370}
                    />
                  ) : (
                    <>
                      <Row className="">
                        <Col md={3}>
                          <p>
                            <b style={{ fontWeight: 600 }}>Total Complain: </b>
                            <span className="font-size-30">
                              {chartsummary.totalComplain}
                            </span>
                          </p>
                        </Col>
                        <Col md={3}>
                          <p>
                            <b style={{ fontWeight: 600 }}>Assigned: </b>
                            <span id="lbl_class">{chartsummary.assigned}</span>
                          </p>
                        </Col>
                        <Col md={2}>
                          <p>
                            <b style={{ fontWeight: 600 }}> Accepted: </b>
                            <span id="lbl_class">{chartsummary.accepted}</span>
                          </p>
                        </Col>
                        <Col md={2}>
                          <p>
                            <b style={{ fontWeight: 600 }}> Process: </b>
                            <span id="lbl_class">{chartsummary.process}</span>
                          </p>
                        </Col>
                        <Col md={2}>
                          <p>
                            <b style={{ fontWeight: 600 }}> Completed: </b>
                            <span id="lbl_class">{chartsummary.completed}</span>
                          </p>
                        </Col>
                        <ReactEcharts
                          style={{ height: "100px" }}
                          option={getOption()}
                        />
                      </Row>
                      {/* table */}
                      <div class="table-responsive" bis_skin_checked="1">
                        <table
                          id="tblSemesterWise"
                          class="table"
                          width="100%"
                          cellpadding="5px"
                          style={{ borderCollapse: "collapse" }}
                        >
                          <thead>
                            <tr>
                              <th>Class</th>
                              <th>Description</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentComplains?.map(std => {
                              return (
                                <tr>
                                  <td>{std.classID?.className}</td>
                                  <td>{std.description}</td>
                                  <td>
                                    {moment(std.complainDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>{std.status}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Developed by 🙋‍♂️
                  <Link href=" #"> Eng zaki ahmed</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

StudentComplain.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(StudentComplain)
