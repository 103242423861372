import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { studentsContext } from "../../context/studentContex"
import "../Dashboard/datatables.scss"

const StudentForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    disabled,
    load,
    studentObj,
    handleshow,
    showpassword,
    showicon,
  } = useContext(studentsContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p> Student Registration</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="studentName"
                      type="text"
                      label="Name"
                      value={studentObj.studentName}
                      onChange={e => handleChange(e)}
                      placeholder="Enter student Name"
                      errorMessage="Enter student Name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="studentPhone"
                      type="number"
                      label="Phone"
                      value={studentObj.studentPhone}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Phone"
                      errorMessage="Enter Phone"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="studentGender"
                      type="select"
                      label="Gender"
                      value={studentObj.studentGender}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Gender"
                      errorMessage="Enter Gender"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select Gender</option>
                      <option vlaue="male">male</option>
                      <option vlaue="female">female</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="studentAddress"
                      type="text"
                      label="Address"
                      value={studentObj.studentAddress}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Address"
                      errorMessage="Enter Address"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="educationalLevel"
                      type="select"
                      label="educational Level"
                      value={studentObj.educationalLevel}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  educational Level"
                      errorMessage="Enter educational Level"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select educational Level</option>
                      <option vlaue="Phd">Phd</option>
                      <option vlaue="Master">Master</option>
                      <option vlaue="Bachelor">Bachelor</option>
                      <option vlaue="Secondary">Secondary</option>
                      <option vlaue="primary">primary</option>
                      <option vlaue="others">others</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="studentStatus"
                      type="select"
                      label="studentStatus"
                      value={studentObj.teacherStatus}
                      onChange={e => handleChange(e)}
                      placeholder="Enter student Status"
                    >
                      <option vlaue="active">active</option>
                      <option vlaue="graduated">graduated</option>
                      <option vlaue="pending">pending</option>
                      <option vlaue="blocked">blocked</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="username"
                      type="email"
                      label="username (Email)"
                      value={studentObj.username}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  username"
                      errorMessage="Enter username "
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  {/* <div className="mb-3">
                  <AvField
                    name="password"
                    type="password"
                    label="password"
                    value={studentObj.password}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter  password"
                    errorMessage="Enter password"
                    validate={{
                      required: { value: true },
                    }}
                  >
                  </AvField>
                </div> */}
                  <div className="mb-3">
                    <Label>password</Label>
                    <InputGroup>
                      <input
                        type={showpassword ? "text" : "password"}
                        value={studentObj.password}
                        name="password"
                        onChange={e => handleChange(e)}
                        className="form-control"
                        required
                        placeholder="enter password"
                        validate={{ required: { value: true } }}
                      />
                      <span
                        className="input-group-append"
                        onClick={() => handleshow()}
                      >
                        <span className="input-group-text">
                          {" "}
                          <i className={showicon}></i>
                        </span>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="parentName"
                      type="text"
                      label="Parent Name (Optional)"
                      value={studentObj.parentName}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Parent Name"
                      errorMessage="Enter Parent Name"
                      validate={{
                        required: { value: false },
                      }}
                    ></AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="parentPhone"
                      type="text"
                      label="Parent Phone (Optional)"
                      value={studentObj.parentPhone}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Parent Phone"
                      errorMessage="Enter Parent Phone"
                      validate={{
                        required: { value: false },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : " Save Student"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default StudentForm
