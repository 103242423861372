import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import { GetAll, postfunc } from "../../services/mainService"
const TasksReport = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [serviceObj, setserviceObj] = useState({
    serviceName: "",
    servicePrice: "",
    customerID: "",
    employeeID: "",
    workType: "",
    quantity: "",
    workStatus: "",
    prductionStatus: "",
  })
  const [loading, setloading] = useState(false)
  const [selectedCustomer, setselectedCustomer] = useState({})
  const [selectedEmployee, setselectedEmployee] = useState({})
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const [currentUserTasks, setcurrentUserTasks] = useState({
    totalTasks: 0,
    totalTasksIncome: 0,
    userAmountAsPercentage: 0,
    serviceTasks: 0,
    salesTasks: 0,

    currentMonthTasks: 0,
    currentMonthTasksIncomes: 0,
    currentMonthToatalAsPercentage: 0,
  })

  const [currentMonthandYear, setcurrentMonthandYear] = useState("")
  const [tasksReportObj, settasksReportObj] = useState({
    employeeID: "",
    startDate: "",
    endDate: "",
  })
  const [employeeData, setemployeeData] = useState([])
  const [showForm, setshowForm] = useState(true)
  const [serviceData, setserviceData] = useState([])
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT
  useEffect(() => {
    async function onload() {
      try {
        const { data: employeeData } = await GetAll("employee", headers)
        let chechArray = Array.isArray(employeeData)
        if (chechArray) {
          setemployeeData(employeeData)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    onload()
  }, [])

  const tog_standard = () => {
    setIsOpen(!isOpen)
    setserviceObj({
      serviceName: "",
      servicePrice: "",
      customerID: "",
    })
    setselectedCustomer({})
    setselectedEmployee({})
    setEditCondition(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    settasksReportObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  let getAdminTaks = async admintasks => {
    try {
      setcurrentUserTasks({
        totalTasks: admintasks?.totalTasks,
        totalTasksIncome: admintasks?.totalTasksIncome,
        userAmountAsPercentage: admintasks?.userAmountAsPercentage,
        serviceTasks: admintasks?.serviceTasks,
        salesTasks: admintasks?.salesTasks,

        currentMonthTasks: admintasks?.DateToDateTasks,
        currentMonthTasksIncomes: admintasks?.DateToDateTasksIncome,
        currentMonthToatalAsPercentage:
          admintasks?.DateToDateToatalAsPercentage,
      })
    } catch (error) {
      console.log(error)
    }
  }
  let getUserTaks = async admintasks => {
    try {
      if (admintasks.status) {
        setcurrentUserTasks({
          totalTasks: admintasks.totalTasks,
          totalTasksIncome: admintasks.totalTasksIncome,
          userAmountAsPercentage: admintasks.userAmountAsPercentage,
          serviceTasks: admintasks.serviceTasks,
          salesTasks: admintasks.salesTasks,

          currentMonthTasks: admintasks.currentMonthTasks,
          currentMonthTasksIncomes: admintasks.currentMonthTasksIncomes,
          currentMonthToatalAsPercentage:
            admintasks.currentMonthToatalAsPercentage,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()

      // return console.log("tasksReportObj", tasksReportObj)
      setloading(true)
      const { data } = await postfunc("tasksReport", tasksReportObj, headers)
      const { status, message, info, employeeServices, summaryData } = {
        ...data,
      }
      if (status) {
        setloading(false)
        setshowForm(false)
        notify(message, "success")
        // mutate("service", fetchAll)
        settasksReportObj({
          employeeID: "",
          startDate: "",
          endDate: "",
        })

        let chechArray = Array.isArray(employeeServices)
        if (chechArray) {
          setserviceData(employeeServices)
        }

        getAdminTaks(summaryData)
      } else {
        notify(message, "error")
        setloading(false)
      }
    } catch (error) {
      notify(error.message, "error")
      setloading(false)
    }
  }
  const back = () => {
    setshowForm(true)
    setserviceData([])
    setloading(false)
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    console.log("data", data)
    setEditCondition(true)
    setserviceObj(data)
    setIsOpen(true)
    setselectedCustomer({
      label: data.customerID?.name + "-" + data.customerID.code,
      value: data.customerID?._id,
    })
    setselectedEmployee({
      label: data.employeeID?.name,
      value: data.employeeID?._id,
    })
  }

  const UserData = {
    columns: [
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      {
        label: "serviceName",
        field: "serviceName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Emp Designed",
        field: "EmployeeName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Emp Printed",
        field: "EmployeePrinted",
        sort: "asc",
        width: 200,
      },

      {
        label: "quantity",
        field: "quantity",
        sort: "asc",
        width: 60,
      },
      {
        label: "workType",
        field: "workType",
        sort: "asc",
        width: 60,
      },

      {
        label: "Design Status",
        field: "workStatus",
        sort: "asc",
        width: 80,
      },
      {
        label: "Print Status",
        field: "prductionStatus",
        sort: "asc",
        width: 80,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: serviceData?.map(cust => {
      const data = { ...cust }
      data.customerName = data.customerID?.name
      data.EmployeeName = data.employeeID?.name
      data.EmployeePrinted = data.ProductionEmployeeID?.name
      data.servicePrice = "$" + data.servicePrice
      data.AmountPaid = "$" + data.AmountPaid
      data.Balance = "$" + data.Balance

      data.date = moment(data.createdAt).format("lll")

      if (data.workStatus == "progress") {
        data.workStatus = (
          <div className="badge bg-warning  font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else if (data.workStatus == "done") {
        data.workStatus = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else if (data.workStatus == "assigned") {
        data.workStatus = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else {
        data.workStatus = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      }

      if (data.prductionStatus == "progress") {
        data.prductionStatus = (
          <div className="badge bg-warning  font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else if (data.prductionStatus == "done") {
        data.prductionStatus = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else if (data.prductionStatus == "assigned") {
        data.prductionStatus = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else {
        data.prductionStatus = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mt-4">
            <Col className="col-10">
              <Breadcrumbs title="Service" breadcrumbItems={breadcrumbItems} />
            </Col>
            {showForm == false ? (
              <Col className="col-2">
                <Button
                  onClick={() => back()}
                  type="button"
                  style={{
                    background: "#0A928C",
                    color: "white",
                  }}
                  disabled={loading}
                  className="mb-4 dropdown-toggle waves-effect waves-light float-end"
                >
                  Back
                </Button>
              </Col>
            ) : null}
          </Row>
          <>
            {showForm ? (
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={HandleSubmitBtn}
                      >
                        <Row className=" mb-3">
                          <Col md={3}>
                            <div className="mb-3">
                              <FormGroup className="form-group">
                                <AvField
                                  type="select"
                                  name="employeeID"
                                  label="select type"
                                  value={tasksReportObj.employeeID}
                                  onChange={e => handleChange(e)}
                                  className="form-control"
                                  errorMessage="please select employeeID  "
                                  validate={{ required: { value: true } }}
                                >
                                  <option vale="">Select Employee</option>
                                  {employeeData?.map(student => (
                                    <option value={student?._id}>
                                      {student.name}
                                    </option>
                                  ))}
                                </AvField>
                              </FormGroup>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <FormGroup className="form-group">
                                <AvField
                                  type="date"
                                  name="startDate"
                                  label="date started"
                                  value={tasksReportObj.startDate}
                                  onChange={e => handleChange(e)}
                                  className="form-control"
                                  errorMessage="please select start date"
                                  validate={{ required: { value: true } }}
                                ></AvField>
                              </FormGroup>
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="mb-3">
                              <FormGroup className="form-group">
                                <AvField
                                  type="date"
                                  name="endDate"
                                  label=" Ended Date "
                                  value={tasksReportObj.endDate}
                                  onChange={e => handleChange(e)}
                                  className="form-control"
                                  errorMessage="please end Date "
                                  validate={{ required: { value: true } }}
                                ></AvField>
                              </FormGroup>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <Label>View</Label>
                              <FormGroup className="form-group">
                                <Button
                                  type="submit"
                                  style={{
                                    background: "#0A928C",
                                    color: "white",
                                  }}
                                  disabled={loading}
                                  className=" dropdown-toggle waves-effect waves-light "
                                >
                                  {/* <i className="ion ion-md-add me-2"></i> */}
                                  {loading ? "loading" : "View Report"}
                                </Button>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <div>
                {/* cards row */}
                <Row>
                  <Col>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium mb-2">
                              Total Tasks
                            </p>
                            <h4 className="mb-0">
                              {currentUserTasks?.totalTasks}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">
                            <span
                              className="avatar-title"
                              style={{ backgroundColor: "#14213D" }}
                            >
                              <i className={"fas fa-clone font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium mb-2">
                              Total Tasks Income
                            </p>
                            <h4 className="mb-0">
                              ${currentUserTasks?.totalTasksIncome}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">
                            <span
                              className="avatar-title"
                              style={{ backgroundColor: "#14213D" }}
                            >
                              <i className={"fas fa-donate font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium mb-2">
                              Date To Date Tasks
                            </p>
                            <h4 className="mb-0">
                              {currentUserTasks?.currentMonthTasks}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm align-self-center rounded-circle ">
                            <span
                              className="avatar-title"
                              style={{ backgroundColor: "#14213D" }}
                            >
                              <i className={"fas fa-clone font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium mb-2">
                              Date To Date Commision
                            </p>
                            <h4 className="mb-0">
                              $
                              {currentUserTasks?.currentMonthToatalAsPercentage}
                            </h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm align-self-center rounded-circle ">
                            <span
                              className="avatar-title"
                              style={{ backgroundColor: "#14213D" }}
                            >
                              <i className={"fas fa-donate font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* data table Row  */}
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <Row className="mb-2">
                          <Col sm={6} md={8} xl={10}>
                            <h4 className="card-title">Tasks Table </h4>
                          </Col>
                        </Row>
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          data={UserData}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TasksReport
