import React, { useEffect, useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBDataTable } from "mdbreact";
// import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  CardHeader,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,

} from "reactstrap";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField} from "availity-reactstrap-validation";
// import { FetchTrensections } from "./TransectionApi";
import Datepicker from "react-datepicker";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Components
import axios from "axios";
import jwtDecode from "jwt-decode";
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import Select from "react-select";
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../../services/mainService"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"
const Subject = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editCondition, setEditCondition] = useState(false);
  const [subjectObj, setsubjectObj] = useState({
    courseID:"",
    subjectName: "",
   
  });
  const [courses, setcourses] = useState([])
  const [selectedCourse, setselectedCourse] = useState({})
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
const [load, setload] = useState(false)

const token = localStorage.getItem("token");
const headers = {
  headers: {
    "Content-Type": "application/json",
    "auth-token": token
  },
};
let epiEndPoint=process.env.REACT_APP_ENDPOINT

async function fetchAll(path) {
  const {data}=await axios.get(epiEndPoint+"/"+path,headers);
  return data
};

const location = useLocation();
Auhtentication(location.pathname)

  useEffect(() => {
   async function onload () {
        const { data: courseList } = await GetAll("courses",headers)
        if(courseList){
            setcourses(courseList)
        }
        const token = localStorage.getItem("token");
        if(token){
            const user = jwtDecode(token);
            setcurrentUser(user)
        }
    }
    onload()
  }, [])

  let CourseData=[]
  const { data:courseList } =  useSwr("subject",fetchAll)
  
  let chechArray=Array.isArray(courseList)
  if(chechArray==true){
    CourseData=courseList
  }
  const tog_standard = () => {
    setIsOpen(!isOpen);
    setsubjectObj({
        courseID:"",
       subjectName: "",
    });
    setselectedCourse({})
    setEditCondition(false);
  };
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ];

  const handleChange = (e) => {
    setsubjectObj((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const notify = (message, type) => toast[type](message);

  const HandleSubmitBtn = async (e) => {
    try {
      setdisabled(true)
      setload(true)
      e.preventDefault();
      const token = localStorage.getItem("token");

      if (editCondition) {
        const { data } = await putfunc("subject", subjectObj,headers)
        console.log(data.info);
        const { status, message, info } = { ...data };
        if(status){
          setdisabled(false)
          setload(false)
           mutate("subject", fetchAll)
          notify(message, "success");
          setIsOpen(false);
          setEditCondition(false)
        }
      } else {
    
 if(Object.keys(selectedCourse).length === 0) return toast.warning("please choose a customer")
 
 subjectObj.courseID=selectedCourse.value
//  subjectObj.userID=currentUser._id
//   return   console.log("subjectObj",subjectObj);
        const { data } = await postfunc("subject", subjectObj,headers)
        const { status, message, info } = { ...data };
        if(status){
          setdisabled(false)
          setload(false)
        setIsOpen(false);
          
          notify(message, "success");
          mutate("subject", fetchAll)
          setsubjectObj({
            date: new Date(),
            description: "",
            amount: "",
          });
        }else{
          notify(message, "error");
        }
      }
    } catch (error) {
      console.log(error.response);
      notify(error.message, "error");
      setdisabled(false)
          setload(false)
    }
  };
  
  const handleEdit = async (transData) => {
    let data = { ...transData };
    setEditCondition(true);
    setsubjectObj(data);
    setIsOpen(true);
    setselectedCourse( {label: data.courseID?.courseName, value: data.courseID?._id,})
  };
  const CourseIDOnachnge = (e) => {
    try {
    //   console.log(e)
      if (e.value) {
        setselectedCourse(e);
    
      }
      
    } catch (error) {
      console.log(e)
      console.log(error)
    }
    
    
  };
  const CoursesData = {
    columns: [
      {
        label: "subjectName",
        field: "subjectName",
        sort: "asc",
        width: 40,
      },

      {
        label: "courseName",
        field: "courseName",
        sort: "asc",
        width: 50,
      },
     
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
     
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: CourseData?.map((cust) => {
      const data = { ...cust };
      data.courseName=data.courseID?.courseName
    

      data.date = moment(data.createdAt).format("lll");
      if (data.Status=="percialPaid") {
        
        data.Status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        );
      } else if (data.Status =="fullPaid") {
       
        data.Status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        );
      }
      else {
        
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        );

      }
    
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      );
      return data;
    }),
  };
 
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="courses" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
              <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Add subject </p>
          </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>  
                    <Row>
                      <Col>
                      <FormGroup>
                    
                      <AvField
                        name="subjectName"
                        label="subject Name"
                        placeholder="Enter subjectName ...."
                        type="text"
                        value={subjectObj.subjectName}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        errorMessage="Enter subject Name"
                        validate={{
                          required: { value: true },
                         
                        }}
                      />
                    </FormGroup>
                      </Col>
                      <Col> 
                      <FormGroup>               
                      <Label>Select Course</Label>
                    <Select
                            name="studentID"
                            value={selectedCourse}
                            placeholder="Select Course"
                            // isClearable={true}
                           isSearchable={true}
                            onChange={(e) => CourseIDOnachnge(e)}
                            options={courses?.map((cust, index) => {
                              return {
                                label: cust.courseName,
                                value: cust._id,
                                key: index,
                              };
                            })}
                          ></Select>
                     
                    </FormGroup>

                      </Col>
                    </Row>                      
                    
                   
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>
                   
                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                        
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ?"saving...":" Save subject"}
                     
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card >
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's courses record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">subject Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{background: "#14213D",color:"white"}}
                        
                        className="waves-effect waves-light float-right"
                      >
                        <i className="ri-add-line"></i> Add subject
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={CoursesData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Subject;
