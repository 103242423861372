import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
// import UserTable from "./usesTable"
import AttendenceForm from "./attendenceForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AttendenceContextProvider from "../../context/attendenceContext"

const Attendence = () => {
  return (
    <React.Fragment>
      <AttendenceContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
            Dashboard | Veltrix - Responsive Bootstrap 5 Admin Dashboard
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="HRM" breadcrumbItem="Employee" />
             <AttendenceForm/>
              {/* <AttendenceForm/> */}
          </div>
        </Container>
      </div>
      </AttendenceContextProvider>
    </React.Fragment>
  )
}

export default Attendence
