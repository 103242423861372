import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { mutate } from "swr"
import { enrollmentContext } from "../../context/enrollmentContext"
import "../Dashboard/datatables.scss"
const EnrollmentTable = () => {
  const [activeTab1, setactiveTab1] = useState("1")
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  const {
    isOpen,
    editCondition,
    classesData,
    enrollmentData,
    tog_standard,
    handleEdit,
    deletefunc,
    setsweetAlertConfirm,
    sweetAlertConfirm,
    handleDelete,
    fetchAll,
    enrollmentObj,
    overTimeStudentsList,
  } = useContext(enrollmentContext)

  let toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const data = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "enrolled Date",
        field: "Date",
        sort: "asc",
        width: 100,
      },
      {
        label: "enrolled Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: enrollmentData?.map(hos => {
      const data = { ...hos }

      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      // data.studentName = data.studentID?.stdiD;
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className
      data.amount = "$" + data.Amount

      data.Date = moment(data.enrolledDate).format("ll")
      // console.log("data.enrolledStatus", data.enrolledStatus)

      if (data.enrolledStatus == "active") {
        data.status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      } else if (data.enrolledStatus == "graduated") {
        data.status = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            <i class="fa fa-graduation-cap"></i> {data.enrolledStatus}
          </div>
        )
      } else if (data.enrolledStatus == "pending") {
        data.status = (
          <div className="badge bg-warning font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      } else {
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  const overTimeStudentData = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "enrolled Date",
        field: "Date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Class EndDate ",
        field: "endDate",
        sort: "asc",
        width: 100,
      },
      {
        label: "enrolled Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: overTimeStudentsList?.map(hos => {
      const data = { ...hos }

      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      // data.studentName = data.studentID?.stdiD;
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className
      data.amount = "$" + data.Amount

      data.Date = moment(data.enrolledDate).format("ll")
      data.endDate = moment(data.classID?.endDate).format("ll")
      data.endDate = (
        <div className="badge bg-danger  font-size-15 badge-pill mr-2">
          {data.endDate}
        </div>
      )

      if (data.enrolledStatus == "active") {
        data.status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      } else if (data.enrolledStatus == "graduated") {
        data.status = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            <i class="fa fa-graduation-cap"></i> {data.enrolledStatus}
          </div>
        )
      } else if (data.enrolledStatus == "pending") {
        data.status = (
          <div className="badge bg-warning font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      } else {
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.enrolledStatus}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          {/* <button onClick={() => handleDelete(data)}   className="btn btn-white btn-sm mr-4">
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Card>
        <CardBody>
          <CardTitle className="h4">Enrollment</CardTitle>

          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab1 === "1",
                })}
                onClick={() => {
                  toggle1("1")
                }}
              >
                Enrollment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab1 === "2",
                })}
                onClick={() => {
                  toggle1("2")
                }}
              >
                over Time Students
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab1} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                {sweetAlertConfirm ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={async () => {
                      try {
                        // return  console.log(IncomeObj)
                        const { data } = await deletefunc(
                          "Enrollment",
                          enrollmentObj,
                          headers
                        )
                        const { status, message } = { ...data }
                        if (status) {
                          toast.success(message)
                          setsweetAlertConfirm(false)
                          mutate("Enrollment", fetchAll)
                        } else {
                          toast.error(message)
                        }
                      } catch (error) {
                        toast.error(error.message)
                      }
                    }}
                    onCancel={() => setsweetAlertConfirm(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                <ToastContainer />

                <MDBDataTable responsive striped bordered data={data} />
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <MDBDataTable
                responsive
                striped
                bordered
                data={overTimeStudentData}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Row>
  )
}

export default EnrollmentTable
