import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import useSWR, { mutate } from "swr"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  FormGroup,
  Label,
  Container,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "./datatables.scss"
import axios from "axios"
import { useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  deletefunc,
} from "../../services/mainService"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"

function Branch() {
  const [isopen, setisopen] = useState(false)
  const [isupdate, setisupdate] = useState(false)
  const [updateopen, setupdateopen] = useState(false)

  const tog_standard = () => {
    setisopen(!isopen)
    setisupdate(false)
    if (!isupdate) {
      setupdatedata("")
    }
  }
  const update_tog = () => {
    setupdateopen(!updateopen)
  }
  const location = useLocation()
  Auhtentication(location.pathname)
  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(url + "/" + path, headers)
    return data
  }

  let drugData = []

  let { data: drugList } = useSWR("branch", fetchAll)

  let chechArray = Array.isArray(drugList)

  if (chechArray) {
    drugData = drugList
  }
  const [updatedata, setupdatedata] = useState({})

  // tiket post
  const handlePost = async (event, value) => {
    // console.log(value)
    try {
      //   setisupdate(false)
      //   console.log("isupdate",isupdate)
      if (isupdate) {
        const { data } = await axios.put(`${url}/branch/${updateid}`, value)
        console.log(data)
        if (data.status) {
          setisopen(false)

          mutate(`branch`, fetchAll)
          toast.success(data.message)
        }
      } else {
        const { data } = await axios.post(`${url}/branch`, value, headers)

        if (data.status) {
          toast.success(data.message)
          setisopen(false)
          mutate(`branch`, fetchAll)
        } else {
          toast.success(data.message)
        }
      }
      //   if(!isupdate){
      // setupdatedata([])
    } catch (error) {
      toast.error(error.message)
    }
    //   }
  }
  // put or update
  const [updateid, setupdateid] = useState([])
  const [Date, setDate] = useState("")

  const doctorupdatData = updatadr => {
    setisupdate(true)
    setisopen(!isopen)
    setupdateid(updatadr._id)
    updatadr.Date = moment(updatadr.Date).format("YYYY-MM-DD")

    setupdatedata(updatadr)
    // setDate(updatadr.DateofBirth)
    console.log(updatadr)
  }

  const [deletedr, setdeletedr] = useState([])
  const [deletealert, setdeletealert] = useState(false)

  const handledoctordelete = deletedata => {
    setdeletedr(deletedata._id)
    setdeletealert(true)
  }
  const handledeletedoctor = async () => {
    try {
      const { data } = await axios.delete(`${url}/branch/${deletedr}`, headers)
      setdeletealert(false)
      if (data.status === "success") {
        toast.success(data.message)
        mutate(`${url}/branch/${deletedr}`, fetchAll)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  //get data pataint

  const data = {
    columns: [
      {
        label: "Branch Name",
        field: "branch_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch Location",
        field: "branch_location",
        sort: "asc",
        width: 270,
      },

      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: drugData?.map(data => {
      data.Date = moment(data.createdAt).format("lll")
      data.amount = "$" + data.Amount
      data.action = (
        <div className="d-flex">
          <button
            className="btn   text-primary "
            onClick={() => doctorupdatData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn btn-white  text-danger  mx-3 "
            onClick={() => handledoctordelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="software Decoration"
            title="branch"
            breadcrumbItem=""
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={10}></Col>
                    {/* <CardTitle className="h4">Stock Table </CardTitle> */}

                    <Col md={2}>
                      <button
                        type="button"
                        onClick={() => {
                          tog_standard()
                        }}
                        className="btn waves-effect waves-light float-end mb-3"
                        style={{
                          backgroundColor: "#607d8e",
                          color: "white",
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Add New Branch
                      </button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isopen}
            togle={() => {
              tog_standard()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Branch Model
              </h5>
            </div>
            <AvForm
              onValidSubmit={(e, v) => {
                handlePost(e, v)
              }}
            >
              <div className="modal-body">
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label>Branch Name </Label>
                      <AvField
                        name="branch_name"
                        placeholder="branch Name"
                        type="text"
                        value={updatedata.branch_name}
                        errorMessage="Enter branch Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label>Branch Location </Label>
                      <AvField
                        name="branch_location"
                        placeholder="branch location"
                        type="text"
                        value={updatedata.branch_location}
                        errorMessage="Enter branch location"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save Branch
                </button>
              </div>
            </AvForm>
          </Modal>
          <ToastContainer />
          {deletealert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handledeletedoctor}
              onCancel={() => setdeletealert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Branch
