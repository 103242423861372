import moment from 'moment';
import jwtDecode from "jwt-decode";
import { useNavigate,useHistory } from "react-router-dom";

export default function Auhtentication(currentpath){
  try {
    return
    const token = localStorage.getItem("token");
    if(token!=null || token!=undefined || token!=""){

    const data = jwtDecode(token);
  if(data){

    if(data.userType=="superAdmin"){
      return
    }
  
    let date2 = moment()
    let susbcriptionDate = moment(data?.SubscriptionExpiredDate)
    let reminedsecondSubcription=susbcriptionDate.diff(date2, 'seconds')

    let TokenDate = moment(data?.tokenExpiredDate)
   let reminedsecondsToken=TokenDate.diff(date2, 'seconds')
   
   const history = useHistory();
   console.log("seconds",reminedsecondSubcription)
   if(reminedsecondSubcription<=0 ||reminedsecondsToken<=0  && data.userType!="superAdmin"){
    localStorage.removeItem("token")
    history.push('/login')
    return
   }
  //  return
   let currentFoundpath=""
   data.menus?.map((menu)=>{
    if(menu.path!=""){
      // if(menu.path!=currentpath){
      
        let findPath=data.menus.find(c=>c.path==currentpath)
        if(findPath){
          currentFoundpath=findPath
        }
      // }
     
    }else if(menu.path==""){
      menu.subMenu?.map((submenu)=>{
        let findPath=menu.subMenu.find(c=>c.path==currentpath)
        if(findPath){
          currentFoundpath=findPath
        }
      })
    }
   })
// console.log("currentFoundpath",currentFoundpath)
   if(currentFoundpath=="" || currentFoundpath==undefined){
     let manMenu=data.menus[0]
    if(manMenu.path!=""){
      history.push(manMenu.path)
    }else{
      let subMenu=manMenu.subMenu[0]
    history.push(subMenu.path)

    }
    
   }
   
}
}
} catch (error) {
  console.log("error",error.message)
}
}