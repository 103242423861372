import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import EnrollmentTable from "./enrollmentTable"
import EnrollmentForm from "./enrollmentForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EnrollmentContextProvider from "../../context/enrollmentContext"

const Enrollment = () => {
 
  return (
    <React.Fragment>
      <EnrollmentContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
            Enrollment | easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="Enrollment" breadcrumbItem="enrollment" />
             <EnrollmentForm/>
              <EnrollmentTable/>
          </div>
        </Container>
      </div>
      </EnrollmentContextProvider>
    </React.Fragment>
  )
}

export default Enrollment
