import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StudentsContextProvider from "../../context/studentContex"
import "../Dashboard/datatables.scss"
import StudentTable from "./studentTable"
import StudentForm from "./studentsForm"

const Students = () => {
  return (
    <React.Fragment>
      <StudentsContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Students|Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="class"
                breadcrumbItem="class"
              />
              <StudentForm />
              <StudentTable />
            </div>
          </Container>
        </div>
      </StudentsContextProvider>
    </React.Fragment>
  )
}

export default Students
