import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"

import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import { useLocation } from "react-router-dom"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import { deletefunc, postfunc, putfunc } from "../../services/mainService"
const Note = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  //   let currentDate = new Date().toJSON().slice(0, 10)
  const [noteObj, setnoteObj] = useState({
    Name: "",
    Address: "",
    phone: "",
    Course: "",
    Days: "",
    Description: "",
    dateVisit: "",
    userID: "",
  })
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)

  const [Subjects, setSubjects] = useState([])
  const [subjectTemp, setsubjectTemp] = useState([])
  const [selectedStudent, setselectedStudent] = useState({})

  const [students, setstudents] = useState([])

  const [classData, setclassData] = useState([])
  const [classTempData, setclassTempData] = useState([])

  const [currentUser, setcurrentUser] = useState({})
  const [stdenrollemntData, setstdenrollemntData] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    async function onload() {
      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        setcurrentUser(user)
      }
    }
    onload()
  }, [])

  let marksData = []
  const { data: marksList } = useSwr("note", fetchAll)
  // console.log("marksList",marksList)
  let chechArray = Array.isArray(marksList)
  if (chechArray == true) {
    marksData = marksList
  }

  const tog_standard = () => {
    setload(false)
    setdisabled(false)
    setIsOpen(!isOpen)
    setnoteObj({
      Name: "",
      Address: "",
      phone: "",
      Course: "",
      Days: "",
      Description: "",
      dateVisit: "",
      userID: "",
    })
    setEditCondition(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setnoteObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()
      const token = localStorage.getItem("token")
      setdisabled(true)
      setload(true)
      if (editCondition) {
        const { data } = await putfunc("note", noteObj, headers)

        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("note", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
          setnoteObj({
            Name: "",
            Address: "",
            phone: "",
            Course: "",
            Days: "",
            Description: "",
            dateVisit: "",
            userID: "",
          })
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (noteObj.dateVisit == "") {
          delete noteObj.dateVisit
        }
        noteObj.userID = currentUser._id
        const { data } = await postfunc("note", noteObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)

          notify(message, "success")
          mutate("note", fetchAll)
          setnoteObj({
            Name: "",
            Address: "",
            phone: "",
            Course: "",
            Days: "",
            Description: "",
            dateVisit: "",
            userID: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
    }
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.dateVisit = moment(data.dateVisit).format("YYYY-MM-DD[T]HH:mm:ss")
    setEditCondition(true)
    setnoteObj(data)
    setIsOpen(true)
  }
  const handleDelete = data => {
    // console.log(data)
    setnoteObj(data)
    setsweetAlertConfirm(true)
  }

  const NoteData = {
    columns: [
      {
        label: "Name",
        field: "Name",
        sort: "asc",
        width: 40,
      },
      {
        label: "phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Address",
        field: "Address",
        sort: "asc",
        width: 60,
      },
      {
        label: "Course",
        field: "Course",
        sort: "asc",
        width: 60,
      },
      {
        label: "Days",
        field: "Days",
        sort: "asc",
        width: 60,
      },
      {
        label: "Description",
        field: "Description",
        sort: "asc",
        width: 60,
      },

      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Created User",
        field: "userName",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: marksData?.map(cust => {
      const data = { ...cust }
      data.userName = data.userID?.name
      data.date = moment(data.dateVisit).format("lll")
      if (data.Status == "percialPaid") {
        data.Status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else if (data.Status == "fullPaid") {
        data.Status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      } else {
        data.Status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.Status}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Note" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Note Table </p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Name"
                            label="Name"
                            placeholder="Enter Name"
                            type="text"
                            value={noteObj.Name}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter Name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Address"
                            label="Address"
                            placeholder="Enter Address"
                            type="text"
                            value={noteObj.Address}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter Address"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="phone"
                            label="phone"
                            placeholder="Enter phone"
                            type="text"
                            value={noteObj.phone}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter phone"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Course"
                            label="Course"
                            placeholder="Enter Course"
                            type="text"
                            value={noteObj.Course}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter Course"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Days"
                            label="Days"
                            placeholder="Enter Days"
                            type="text"
                            value={noteObj.Days}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter Days"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="dateVisit"
                            label="Date (Optional)"
                            placeholder="Enter Date (Optional)"
                            type="datetime-local"
                            value={noteObj.dateVisit}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter dateVisit"
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="Description"
                            label="Description"
                            placeholder="Enter Description"
                            type="textarea"
                            maxlength="255"
                            value={noteObj.Description}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter Description"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : "  Save Note"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          {sweetAlertConfirm ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={async () => {
                try {
                  // return  console.log(IncomeObj)
                  const { data } = await deletefunc("note", noteObj, headers)
                  const { status, message } = { ...data }
                  if (status) {
                    toast.success(message)
                    setsweetAlertConfirm(false)
                    mutate("note", fetchAll)
                  } else {
                    toast.error(message)
                  }
                } catch (error) {
                  toast.error(error.message)
                }
              }}
              onCancel={() => setsweetAlertConfirm(false)}
            >
              make sure before you delete!
            </SweetAlert>
          ) : null}
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's Subjects record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">Note Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-right"
                      >
                        <i className="ri-add-line"></i> Add Note
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={NoteData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Note
