import { AvField, AvForm } from "availity-reactstrap-validation"
import axios from "axios"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap"
import useSWR, { mutate } from "swr"
// import "./datatables.scss"
import { MDBDataTable } from "mdbreact"
// import { FilePond } from "react-filepond"
import FilePondPluginFileEncode from "filepond-plugin-file-encode"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import { FilePond, registerPlugin } from "react-filepond"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { ToastContainer, toast } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { GetAll, postfunc, putfunc } from "../../services/mainService"
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileEncode
)
const Certificate = () => {
  const [isopen, setisopen] = useState(false)
  const [isupdate, setisupdate] = useState(false)
  const [Students, setStudents] = useState([])
  const [studentID, setstudentID] = useState("")
  const [loading, setloading] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [filesimade, setFilesimage] = useState([])
  const [studentClasses, setstudentClasses] = useState([])
  const [classload, setclassload] = useState(false)
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  const tog_standard = () => {
    setisopen(!isopen)
    setisupdate(false)
    if (!isupdate) {
      setupdatedata("")
    }
  }

  let url = process.env.REACT_APP_ENDPOINT

  // get data
  async function fetchr() {
    const { data } = await axios.get(`${url}/certificate`, headers)
    return data
  }
  const { data: StdCertification, error } = useSWR(`${url}/certificate`, fetchr)
  //   console.log("StdCertification",purchaseData)
  let checkstudents = Array.isArray(StdCertification)
  let certificateData = []
  if (checkstudents) {
    certificateData = StdCertification
  }
  const [updatedata, setupdatedata] = useState({})

  const [ticketObj, setticketObj] = useState({})

  const handleChange = e => {
    setticketObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  // const StudentIDOnachnge = e => {
  //   try {
  //     if (e.value) {
  //       setstudentID(e)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleSubmit = async (event, value) => {
    try {
      value.studentID = studentID.value
      const formData = new FormData()
      formData.append("certificateType", value.certificateType)
      formData.append("studentID", value.studentID)
      formData.append("photo", filesimade[0].file)
      formData.append("status", value.status)
      formData.append("issueDate", value.issueDate)
      formData.append("certID", value.certID)
      // formData.append("courseName", value.courseName)
      formData.append("classID", value.classID)
      console.log("filesimade[0].file", filesimade[0].file)
      let imageSize = filesimade[0].file.size
      let currentSize = imageSize / 1000
      if (currentSize > 500) {
        return toast.warning(" image size ku waa inuu ka yaradaa  500kb ")
      }
      // console.log("currentSize", currentSize)

      setloading(true)

      value.photo = filesimade[0].file
      console.log("value", value)
      if (isupdate) {
        value._id = updatedata._id
        // return console.log("updatedata", updatedata)
        const { data } = await putfunc("certificate", formData, headers)
        if (data.status === true) {
          setisopen(false)
          setupdatedata("")
          setFilesimage([])
          toast.success(data.message)
          mutate(`${url}/certificate`, fetchr)
          setisupdate(false)
        } else {
          toast.warning(data.message)
        }
        setloading(false)
      } else {
        // const { data } = await axios.post(`${url}/certificate`, ticketObj)
        if (studentID == "") {
          return toast.error("pls select student")
        }

        // return console.log("value", value)

        const { data } = await postfunc("certificate", formData, headers)
        if (data.status === true) {
          toast.success(data.message)
          setFilesimage([])
          setisopen(false)
          setticketObj("")
          mutate(`${url}/certificate`, fetchr)
        } else {
          toast.warning(data.message)
        }
        setloading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setloading(false)
    }
    //   }
  }
  // put or update
  const [updateid, setupdateid] = useState([])

  const handleEdit = updateObj => {
    setisupdate(true)
    setisopen(!isopen)
    // updateObj.issueDate = moment(updateObj.issueDate).format("YYYY-MM-DD")
    updateObj.issueDate = moment(updateObj.issueDate).format(
      "YYYY-MM-DD[T]HH:mm:ss"
    )
    setupdateid(updateObj._id)
    setupdatedata(updateObj)
    setstudentID({
      label: updateObj.studentID?.studentName + "-" + updateObj.studentID.stdID,
      value: updateObj.studentID?._id,
    })
  }

  // delete data
  const [DeletedID, setDeletedID] = useState("")
  const [deletealert, setdeletealert] = useState(false)

  const handledelete = deletedata => {
    setDeletedID(deletedata._id)
    setsweetAlertConfirm(true)
  }

  let StudentIDOnachnge = async event => {
    try {
      setclassload(true)
      if (event.value) {
        setstudentID(event)
      }
      const { data } = await axios.get(
        `${url}/certificate/studentClases/${event.value}`,
        headers
      )
      if (data.status) {
        if (Array.isArray(data.studentsClasses)) {
          setstudentClasses(data.studentsClasses)
        }
      } else {
        toast.error(data.message)
      }
      setclassload(false)
    } catch (error) {
      toast.error(error.message)
      setclassload(false)
    }
  }
  // get course
  useEffect(() => {
    async function onload() {
      const { data: StudentsData } = await GetAll("student", headers)
      let checkstudents = Array.isArray(StudentsData)
      if (checkstudents) {
        setStudents(StudentsData)
      }
    }
    onload()
  }, [])

  const data = {
    columns: [
      {
        label: "Image",
        field: "ViewImage",
        sort: "asc",
        width: 100,
      },
      {
        label: "Cert ID",
        field: "certID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Student Name",
        field: "studentName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Course Name",
        field: "courseName",
        sort: "asc",
        width: 100,
      },
      {
        label: "issueDate",
        field: "DateIss",
        sort: "asc",
        width: 100,
      },
      {
        label: "registerDate",
        field: "registerDate",
        sort: "asc",
        width: 100,
      },

      {
        label: "certificate Type",
        field: "certificateType",
        sort: "asc",
        width: 100,
      },

      {
        label: "status",
        field: "status",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: certificateData?.map(data => {
      data.course = data.courseId?.name
      // data.course=data.courseId?.courseName
      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      data.courseName = data.classID?.courseID?.courseName
      data.DateIss = moment(data.issueDate).format("ll")
      data.registerDate = moment(data.registerDate).format("ll")
      data.ViewImage = (
        <div className="d-flex">
          <img
            className="rounded"
            alt="not found"
            width={75}
            height={70}
            src={data.photoUrl}
          />
        </div>
      )
      data.action = (
        <div className="d-flex">
          <button
            className="btn   text-success "
            onClick={() => handleEdit(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn btn-white  text-danger  mx-3 "
            onClick={() => handledelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>software | Certificate </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="software"
              title="courses"
              breadcrumbItem="certificate"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center  mb-3">
                      <Col md={8}>
                        <CardTitle className="h4">Certificate Table </CardTitle>
                      </Col>

                      <Col md="4">
                        <div className="float-end d-none d-md-block">
                          <Button
                            type="button"
                            onClick={() => {
                              tog_standard()
                            }}
                            className="btn waves-effect waves-light"
                            style={{
                              backgroundColor: "#14213D",
                              color: "white",
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            Add Certificate
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Modal
                      isOpen={isopen}
                      togle={() => {
                        tog_standard()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Add New Certificate
                        </h5>
                      </div>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleSubmit(e, v)
                        }}
                      >
                        <div className="modal-body">
                          <Row>
                            <Col md="6">
                              <Label>Select student</Label>
                              <Select
                                name="studentID"
                                value={studentID}
                                // isClearable={true}
                                isSearchable={true}
                                onChange={e => StudentIDOnachnge(e)}
                                options={Students?.map((student, index) => {
                                  return {
                                    label:
                                      student.studentName + "-" + student.stdID,
                                    value: student._id,
                                    key: index,
                                  }
                                })}
                              ></Select>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>certificate Id</Label>
                                <AvField
                                  name="certID"
                                  type="text"
                                  onChange={handleChange}
                                  value={updatedata.certID}
                                  errorMessage="Enter certificate id "
                                  className="form-control"
                                  placeholder="Enter certificate id "
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 mt-2">
                                {classload ? (
                                  <div>
                                    <Spinner
                                      className="align-center"
                                      color="success"
                                      animation="border"
                                      role="status"
                                      // style={{ width: "2rem", height: "1rem" }}
                                    ></Spinner>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <AvField
                                  type="select"
                                  name="classID"
                                  label="Select Class"
                                  value={updatedata.classID}
                                  // onChange={e => handleChange(e)}
                                  onChange={handleChange}
                                  className="form-control "
                                  errorMessage="please select Class "
                                  validate={{ required: { value: true } }}
                                >
                                  <option vale="">Select Class</option>
                                  {studentClasses?.map(clas => (
                                    <option value={clas._id}>
                                      {clas.className}
                                    </option>
                                  ))}
                                </AvField>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label>issue Date</Label>
                                <AvField
                                  name="issueDate"
                                  //   placeholder="purchase Price"
                                  //   placeholder="Date In"
                                  type="datetime-local"
                                  value={updatedata.issueDate}
                                  onChange={handleChange}
                                  errorMessage="Enter issueDate"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label>certificate Type</Label>
                                <AvField
                                  name="certificateType"
                                  type="text"
                                  onChange={handleChange}
                                  value={updatedata.certificateType}
                                  errorMessage="Enter certificate Type"
                                  className="form-control"
                                  placeholder="Enter certificate Type"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label>status</Label>
                                <AvField
                                  name="status"
                                  type="select"
                                  onChange={handleChange}
                                  value={updatedata.status}
                                  errorMessage="Enter status"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom02"
                                >
                                  <option value="">select status</option>
                                  <option value="taken">taken</option>
                                  <option value="order">order</option>
                                  <option value="pending">pending</option>
                                  <option value="blocked">blocked</option>
                                </AvField>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label>Upload Image</Label>
                                <FilePond
                                  className="mt-3 mb-5 mt-lg-0"
                                  acceptedFileTypes={[
                                    "image/png, image/jpeg, image/gif",
                                  ]}
                                  // accept="image/png, image/jpeg, image/gif"
                                  files={filesimade}
                                  maxFiles={1}
                                  data-max-file-size="1mb"
                                  allowReorder={true}
                                  allowMultiple={false}
                                  onupdatefiles={setFilesimage}
                                  labelIdle='Drag & Drop your files or{" "}
                                <span class="filepond--label-action">
                                  Browse
                                </span>
                                '
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                              tog_standard()
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            {loading == true
                              ? "saving..."
                              : "  Save Certificate"}
                          </button>
                        </div>
                      </AvForm>
                    </Modal>
                    <ToastContainer />

                    <MDBDataTable responsive striped bordered data={data} />

                    {sweetAlertConfirm ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={async () => {
                          try {
                            // return  console.log(receiptObj)
                            const { data } = await axios.delete(
                              `${url}/certificate/${DeletedID}`,
                              headers
                            )
                            const { status, message } = { ...data }
                            if (status) {
                              toast.success(message)
                              setsweetAlertConfirm(false)
                              mutate(`${url}/certificate`, fetchr)
                            } else {
                              toast.error(message)
                            }
                          } catch (error) {
                            toast.error(error.message)
                          }
                        }}
                        onCancel={() => setsweetAlertConfirm(false)}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Certificate
