import React, { useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import useSWR, { mutate } from "swr"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import { MDBInput } from "mdbreact"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  Button,
  Label,
  Container,
  Table,
  ModalFooter,
  FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useReactToPrint } from "react-to-print"
// import headerImage from "../../assets/images/poly.png"
import axios from "axios"
import { useEffect } from "react"
// import "./datatables.scss"
import { MDBDataTable } from "mdbreact"
import { ToastContainer, toast } from "react-toastify"
import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  deletefunc,
} from "../../services/mainService"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"

const Supplier = () => {
  const [isopen, setisopen] = useState(false)
  const [isupdate, setisupdate] = useState(false)
  const [updateopen, setupdateopen] = useState(false)
  const [loading, setloading] = useState(false)

  const tog_standard = () => {
    setisopen(!isopen)
    setisupdate(false)
    if (!isupdate) {
      setupdatedata("")
    }
  }
  const update_tog = () => {
    setupdateopen(!updateopen)
  }

  const location = useLocation()
  Auhtentication(location.pathname)
  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  // get data
  async function fetchr() {
    const { data } = await axios.get(`${url}/suplier`, headers)
    return data
  }
  const { data: tiketdata, error } = useSWR(`${url}/suplier`, fetchr)
  const [updatedata, setupdatedata] = useState([])

  // tiket post
  const handlerecpost = async (event, value) => {
    // console.log(value)
    try {
      setloading(true)
      if (isupdate) {
        const { data } = await axios.put(
          `${url}/suplier/${updateid}`,
          value,
          headers
        )
        console.log(data)
        if (data.status == true) {
          setisopen(false)
          setupdatedata("")

          toast.success(data.message)
          mutate(`${url}/suplier`, fetchr)
        }
        setloading(true)
      } else {
        const { data } = await axios.post(`${url}/suplier`, value, headers)

        if (data.status == true) {
          toast.success(data.message)
          setisopen(false)
          mutate(`${url}/suplier`, fetchr)
        }
        setloading(true)
      }
      //   if(!isupdate){
      // setupdatedata([])
    } catch (error) {
      setloading(true)
      toast.error(error.message)
    }
    //   }
  }
  // put or update
  const [updateid, setupdateid] = useState([])

  const doctorupdatData = updatadr => {
    setisupdate(true)
    setisopen(!isopen)
    setupdateid(updatadr._id)
    setupdatedata(updatadr)
  }

  // delete data
  const [deletedr, setdeletedr] = useState([])
  const [deletealert, setdeletealert] = useState(false)

  const handledoctordelete = deletedata => {
    setdeletedr(deletedata._id)
    setdeletealert(true)
  }

  const handledeletedoctor = async () => {
    // console.log("delte")
    try {
      const { data } = await axios.delete(`${url}/suplier/${deletedr}`, headers)
      setdeletealert(false)
      if (data.status === true) {
        toast.success(data.message)

        mutate(`${url}/suplier`, fetchr)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const [viewData, setviewData] = useState({})
  const [showModel, setshowModel] = useState(false)

  let togle = () => {
    setshowModel(!showModel)
  }

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "prescription",
  })

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 100,
      },

      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tiketdata?.map(data => {
      //   data.pateint_name = data.pateint_id?.Name
      data.createdAt = moment(data.createdAt).format("lll")

      data.action = (
        <div className="d-flex">
          <button
            className="btn   text-primary "
            onClick={() => doctorupdatData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn btn-white  text-danger  mx-3 "
            onClick={() => handledoctordelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Software Decoration"
            title="Inventory"
            breadcrumbItem="supplaier"
          />

          <Row>
            <Card>
              <CardBody>
                {/* post model start */}
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <Row>
                      <Col sm={6} md={4} xl={3}>
                        <div className="my-4 text-center">
                          <button
                            type="button"
                            onClick={() => {
                              tog_standard()
                            }}
                            className="btn waves-effect waves-light"
                            style={{
                              backgroundColor: "#607d8e",
                              color: "white",
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            ADD
                          </button>

                          <Modal
                            isOpen={isopen}
                            togle={() => {
                              tog_standard()
                            }}
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title mt-0"
                                id="myModalLabel"
                              >
                                Suplier Model
                              </h5>
                            </div>
                            <AvForm
                              onValidSubmit={(e, v) => {
                                handlerecpost(e, v)
                              }}
                            >
                              <div className="modal-body">
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label> Name :</Label>
                                      <AvField
                                        name="name"
                                        placeholder="Name "
                                        type="text"
                                        value={updatedata.name}
                                        errorMessage="Enter Name"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label>Phone :</Label>
                                      <AvField
                                        name="phone"
                                        placeholder="Phone"
                                        type="text"
                                        value={updatedata.phone}
                                        errorMessage="Enter child sex"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  onClick={() => {
                                    tog_standard()
                                  }}
                                  className="btn btn-secondary waves-effect"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  disabled={loading}
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  {loading ? "saving..." : "Save Supplier"}
                                </button>
                              </div>
                            </AvForm>
                          </Modal>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <MDBDataTable responsive striped bordered data={data} />
              </CardBody>
            </Card>
          </Row>

          {/* sweetAlert */}
          {deletealert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handledeletedoctor}
              onCancel={() => setdeletealert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Supplier
