import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import TeacherTable from "./teacherTable"
import TeacherForm from "./teacherForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TeacherContextProvider from "../../context/teacherContext"

const Teacher = () => {
  return (
    <React.Fragment>
      <TeacherContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
          Teachers |easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="class" breadcrumbItem="class" />
             <TeacherForm/>
              <TeacherTable/>
          </div>
        </Container>
      </div>
      </TeacherContextProvider>
    </React.Fragment>
  )
}

export default Teacher
