import React, { useState,useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {ExpenseContext} from "../../context/expenseContext"

const ExpenseForm = () => {
  const { isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    expenseObj ,
    disabled,
    load
  } =
  useContext(ExpenseContext);
  return (
    <Row>
    <Col sm={6} md={4} xl={3} className="mt-4">
      <div className="text-center"></div>
    
      <Modal   isOpen={isOpen} toggle={tog_standard}>          
        <ModalHeader
          style={{
            background: "#14213D",
            color: "white",
            border: "#0A928C",
          }}
          toggle={tog_standard}
        >
          {" "}
          <p>Add Expense</p>
        </ModalHeader>
        <AvForm
          className="needs-validation"
          onValidSubmit={HandleSubmitBtn}
        >                
          <ModalBody>
            <Row>     
              <Col lg={6}>
                <div className="mb-3">
                <AvField
                    name="type"
                    type="text"
                    label="Expense Type"
                    value={expenseObj.type}
                    onChange={(e) => handleChange(e)}                   
                    placeholder="Enter  Expense type like rent "
                    errorMessage="Enter  Type"
                    validate={{
                      required: { value: true },
                    }}
                  > 
                    {/* <option vlaue="">Select expense Type</option>
                    <option vlaue="salary">Salary</option>
                    <option vlaue="billing">billing</option>
                    <option vlaue="transportation">billing</option> */}
                   
                  </AvField>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                <AvField
                    name="name"
                    type="text"
                   label="expense name"
                    value={expenseObj.name}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter expense name"
                    errorMessage="Enter expense name "
                    validate={{
                      required: { value: true },
                    }}
                  > 
                
                   
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
            <Col lg={6}>
                <div className="mb-3">
                  <AvField
                    name="amount"
                    type="number"
                    label="amount"
                    value={expenseObj.amount}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter amount "
                    errorMessage="Enter amount"
                    validate={{
                      required: { value: true },
                    }}
                  >
                  </AvField>
                </div>
              </Col>
            <Col lg={6}>
                <div className="mb-3">
                <AvField
                    name="date"
                    type="datetime-local"
                    label="expense Date"
                    
                    value={expenseObj.date}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter date "
                    errorMessage="Enter date"
                    validate={{
                      required: { value: true },
                    }}
                  >
                 
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                <AvField
                    name="description"
                    type="textarea"
                    maxlength="255"
                    label="description"
                    placeholder="This textarea has a limit of 225 chars."
                    value={expenseObj.description}
                    onChange={(e) => handleChange(e)}
                    errorMessage="Enter description"
                    validate={{
                      required: { value: true },
                    }}
                  />

                </div>
              </Col>
            
            </Row>

           
           
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="danger"
              onClick={tog_standard}
              className="waves-effect"
            >
              Close
            </Button>
            <Button
              type="submit"
              style={{
                background: "#14213D",
                color: "white",
                border: "#0A928C",
              }}
              disabled={disabled}
              className="waves-effect waves-light"
            >
              {load ?"saving...":" Save Expense"}
             
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Col>
    <ToastContainer />
  </Row>

  )
}

export default ExpenseForm
