import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../services/mainService"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import Auhtentication from ".././authMidlleware/userMiddleWare"
import axios from "axios"
export const courseContext = createContext()
courseContext.displayName = "courseContext"

const CourseContextProvider = props => {
    const [courseObj, setcourseObj] = useState({
        courseName: "",
        coursePrice: "",
        courseDuration: "", 
        courseLevel:"", 
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const token = localStorage.getItem("token");
const headers = {
  headers: {
    "Content-Type": "application/json",
    "auth-token": token
  },
};
let epiEndPoint=process.env.REACT_APP_ENDPOINT

async function fetchAll(path) {
  const {data}=await axios.get(epiEndPoint+"/"+path,headers);
  return data
};

  useEffect(() => {
    const getclass = async () => {
      try {
     
      } catch (error) {
          console.log(error.message);
        
      }
    };
    getclass();

  }, []);
  const location = useLocation();
Auhtentication( location.pathname)
//   useSwr("branch",getMyprint);
let { data:courseslist } = useSwr("courses",fetchAll)
let coursesData=[]
let chechArray=Array.isArray(courseslist)

if(chechArray==true){
  coursesData=courseslist
}
  const notify = (message, type) => toast[type](message);
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setcourseObj({
        courseName: "",
        coursePrice: "",
        courseDuration: "", 
        courseLevel:"", 
    })
    setEditCondition(false)
  }
  
  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {
       
        const {data} = await putfunc("courses",courseObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("courses", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setcourseObj({
            courseName: "",
            coursePrice: "",
            courseDuration: "", 
            courseLevel:"", 
          })
        }else{
          setdisabled(false)
          setload(false)
          notify(message, "error");
        }
      } else {
       
      

        const {data} = await postfunc("courses",courseObj,headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("courses", fetchAll)
          setcourseObj({
            courseName: "",
            coursePrice: "",
            courseDuration: "", 
            courseLevel:"", 
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
          setload(false)
    }
  }

  const handleChange = e => {
    setcourseObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async (transData) => {
    let data = { ...transData };
    data.courseID = data.courseID?._id;
    data.teacherID = data.teacherID?._id;
    
    data.startedDate = moment(data.startedDate).format("YYYY-MM-DD");
    data.endDate = moment(data.endDate).format("YYYY-MM-DD");
    data.date = new Date(data.date);
    setEditCondition(true);
    setcourseObj(data);
    setIsOpen(true);
  };

  return (
    <courseContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        coursesData,
        editCondition,
        courseObj,
        disabled,
        load
      }}
    >
      {props.children}
    </courseContext.Provider>
  );


}

export default CourseContextProvider
