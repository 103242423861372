import { useContext, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import "../Dashboard/datatables.scss"

import { Field, FieldArray, Form, Formik } from "formik"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify"
import "toastr/build/toastr.min.css"
import { attendenceContext } from "../../context/attendenceContext"

const AttendenceForm = () => {
  const [classdays, setclassdays] = useState([])
  const {
    collapse,
    attendenceObj,
    isOpen,
    editCondition,
    tog_standard,
    HandleSubmitBtn,
    handleChange,
    handleEdit,
    handleCheckBoxChange,
    collapseToggle,
    btnCancelTogle,
    userPermessions,
    Users,
    studentsData,
    setuserImage,
    userImage,
    setisFits,
    isFits,
    setattendenceObj,
    handlechangeImage,
    handleSubmitFormik,
    initialValues,
    enrollmentData,
    teachersData,
    tempClassData,
    classData,
    setclassData,
    setenrollmentData,
    TempenrollmentData,
    disabled,
    load,
  } = useContext(attendenceContext)
  return (
    <Row>
      <Col sm={12}>
        <Card className="position-sticky">
          <CardHeader style={{ background: "#14213D", color: "success" }}>
            {/* <h4>User Roles</h4> */}
            <Button
              color="success"
              outline
              className="float-end text-white  waves-effect waves-light"
              onClick={collapseToggle}
            >
              Add Attendence
            </Button>
          </CardHeader>

          <Collapse isOpen={collapse}>
            <div id="basic-collapse">
              <CardBody>
                {/* <h5>Personal Information</h5>
                    <hr /> */}

                <Formik
                  enableReinitialize={true}
                  initialValues={
                    editCondition
                      ? {
                          id: attendenceObj._id,
                          studentID: attendenceObj.studentID,
                          courseID: attendenceObj.courseID,
                          classID: attendenceObj.classID,
                          attended: attendenceObj.attended,
                        }
                      : initialValues
                  }
                  // validationSchema={invoiceSchema}
                  onSubmit={handleSubmitFormik}
                >
                  {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                      <div>
                        <Row>
                          <Col md="4">
                            <Label>Teacher</Label>
                            <Field
                              as="select"
                              name="teacherID"
                              required
                              onChange={event => {
                                setFieldValue(`teacherID`, event.target.value)

                                const teachersClass = tempClassData.filter(
                                  std =>
                                    std.teacherID?._id == event.target.value
                                )
                                setclassData(teachersClass)
                                setenrollmentData([])
                                setclassdays([])
                              }}
                              className="form-control"
                              errorMessage="please teacher"
                              placeholder="Enter username"
                            >
                              <option value="">Select Teacher</option>
                              {teachersData?.map(teacher => (
                                <option value={teacher._id}>
                                  {teacher.teacherName}
                                </option>
                              ))}
                            </Field>
                          </Col>
                          <Col md="4">
                            <Label>Select Class</Label>
                            <Field
                              as="select"
                              name="classID"
                              required
                              onChange={event => {
                                setFieldValue(`classID`, event.target.value)
                                setclassdays()
                                // console.log(TempenrollmentData)
                                const clasData = classData.find(
                                  clas => clas._id == event.target.value
                                )
                                setclassdays(clasData.days)

                                const classStudentsEnrolled =
                                  TempenrollmentData.filter(
                                    enroll =>
                                      enroll.classID?._id ==
                                        event.target.value &&
                                      enroll.enrolledStatus == "active"
                                  )
                                // console.log(classStudentsEnrolled)
                                setenrollmentData(classStudentsEnrolled)
                              }}
                              className="form-control"
                              errorMessage="please teacher"
                              placeholder="Enter username"
                            >
                              <option value="">Select Class</option>
                              {classData?.map(classData => (
                                <option value={classData._id}>
                                  {classData.className}
                                </option>
                              ))}
                            </Field>
                          </Col>
                          <Col md="4">
                            <Label>Select Day</Label>
                            <Field
                              as="select"
                              name="day"
                              required
                              className="form-control"
                              errorMessage="please teacher"
                              placeholder="Enter username"
                            >
                              <option value="">Select Day</option>
                              {classdays?.map(classday => (
                                <option value={classday.value}>
                                  {classday.label}
                                </option>
                              ))}
                            </Field>
                          </Col>
                        </Row>
                        <hr></hr>

                        <FieldArray name="items">
                          {({ insert, remove, push }) => (
                            <div>
                              {enrollmentData?.map((items, index) => (
                                <Row key={index}>
                                  <Col className="mb-3">
                                    <FormGroup>
                                      <Label for="basicpill-namecard-input24">
                                        {items?.studentID?.studentName}
                                      </Label>
                                      <Field
                                        as="select"
                                        name={`items.${index}.studentID`}
                                        hidden
                                        // values={items._id}
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                      >
                                        <option>{items.studentName}</option>
                                        {/* <option value="">Choose service</option> */}
                                        {/* {studentsData?.map((std) => (

                                          ))} */}
                                      </Field>
                                    </FormGroup>

                                    {/* hidden input */}
                                  </Col>
                                  <Col className="mb-3">
                                    <FormGroup>
                                      <Field
                                        type="checkbox"
                                        // checked={false}
                                        defaultChecked={true}
                                        name={`items.${index}.attended`}
                                        onChange={event => {
                                          setFieldValue(
                                            `items.${index}.attended`,
                                            event.target.checked
                                          )
                                          console.log(
                                            "event.target.checked",
                                            event.target.checked
                                          )

                                          setFieldValue(
                                            `items.${index}.studentID`,
                                            items.studentID?._id
                                          )
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ))}

                              {/* } */}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <hr />
                      <Button
                        type="submit"
                        // color="warning"
                        disabled={disabled}
                        style={{ backgroundColor: "#14213D" }}
                        className="btn-rounded mb-2  me-2 btn btn-success float-right "
                      >
                        {load ? "saving..." : "Save Attendence"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </div>
          </Collapse>
        </Card>
      </Col>
      {isFits ? (
        <Lightbox
          mainSrc={userImage}
          enableZoom={false}
          imageCaption={"zakarie ahmed hashi image"}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      <ToastContainer />
    </Row>
  )
}

export default AttendenceForm
