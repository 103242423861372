import React, { createContext, useState, useEffect } from "react"
import {
    Col,
    Container,
    Button,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    ModalFooter,
    Card,
    CardBody,
    CardTitle,
  } from "reactstrap"
import moment from "moment"
import {
    GetAll,
    fetchAll,
    postfunc,
    putfunc,
} from "../../services/mainService"
import { MDBDataTable } from "mdbreact"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"

const Feereport = () => {
    const [feeObj, setfeeObj] = useState({
        classID: "",
        status: "",
      })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [Showreceipt, setShowReceipt] = useState(false)
  const [studentData, setstudentData] = useState([])
  const [classesData, setclassesData] = useState([])
  const [feeData, setfeeData] = useState([])
  const [feeTemp, setfeeTemp] = useState([])

  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  useEffect(() => {
    const getfee = async () => {
      try {
        const { data: StudentsList } = await GetAll("student",headers)
        const { data: classList } = await GetAll("class",headers)
        const { data: feeList } = await GetAll("fee",headers)
        let checkstudents=Array.isArray(StudentsList)
        let checkclass=Array.isArray(classList)
        let checkfeee=Array.isArray(feeList)
        
        if(checkstudents){  
          setstudentData(StudentsList)
        }
        if(checkclass){
          setclassesData(classList)
        }
        if(checkfeee){
          setfeeTemp(feeList)
          setfeeData(feeList)
        }

      } catch (error) {
          console.log(error.message);
        
      }
    };
    getfee();

  }, []);

  const notify = (message, type) => toast[type](message);
 
  const HandleSubmitBtn = async e => {
    try {
        if(feeObj.status=='due'){
            let feeDueList=feeTemp.filter(fee => fee.classID?._id==feeObj.classID && fee.feeStatus !=='fullPaid')
            
            setfeeData(feeDueList)
        }else{
            let feeList=feeTemp.filter(fee => fee.classID?._id==feeObj.classID && fee.feeStatus ==feeObj.status)
            
            setfeeData(feeList)
        }
        

    } catch (error) {
      console.log(error.response)
      
    }
  }
  const handleChange = e => {
    setfeeObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = feeData?.map((cust) => {
    const data = { ...cust };
    data.studentName = data.studentID?.studentName
    data.stdID = data.studentID?.stdID
    data.courseName = data.courseID?.courseName;
    data.className = data.classID?.className;

    data.date = moment(data.createdAt).format("lll")
      const obj={ 
        "student name":data.studentName,
        "stdID":data.stdID,
        "courseName":data.courseName,
        "className":data.className,
        "feeName":data.feeName,
        "Total":"$"+data.feeAmount,
        "paid":"$"+data.feePaid,
        "feeBalance":"$"+data.feeBalance,
        "Status":data.feeStatus,
        "Generated date":data.date,
       }

    return obj;
  });
  const data = {
    columns: [
      {
        label: "Student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "StudentID",
        field: "stdID",
        sort: "asc",
        width: 150,
      },
      {
        label: "Course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Class",
        field: "className",
        sort: "asc",
        width: 270,
      },
      {
        label: "fee Name",
        field: "feeName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Amount",
        field: "totalAmount",
        sort: "asc",
        width: 200,
      },

      {
        label: "Paid",
        field: "Paid",
        sort: "asc",
        width: 100,
      },
      {
        label: "Balance",
        field: "Balance",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "GeneratedDate",
        field: "date",
        sort: "asc",
        width: 100,
      },
    //   {
    //     label: "receipt",
    //     field: "receipt",
    //     sort: "asc",
    //     width: 100,
    //   },
    //   {
    //     label: "Action",
    //     field: "action",
    //     sort: "asc",
    //     width: 100,
    //   },
     
    ],
    rows: feeData?.map(hos => {
      const data = { ...hos }     

      data.studentName = data.studentID?.studentName
      data.stdID = data.studentID?.stdID
      data.courseName = data.courseID?.courseName;
      data.className = data.classID?.className;

      data.date = moment(data.createdAt).format("lll")

      data.totalAmount="$" + data.feeAmount
      data.Paid="$" + data.feePaid
      data.Balance="$" + data.feeBalance

      if (data.feeStatus=="percialPaid") {
        data.status = "percial Paid";
        data.status = (
          <div className="badge bg-info  font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        );
      } else if (data.feeStatus =="fullPaid") {
        data.status = "Full paid";
        data.status = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        );
      }
      else {
        
        data.status = "un paid";
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.feeStatus}
          </div>
        );

      }
    
    
      return data
    }),
  }
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
      <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="class" breadcrumbItem="class" />
            <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
          <AvForm
          className="needs-validation"
          onValidSubmit={HandleSubmitBtn}
        >  

            <Row className=" mb-3">
          
              <Col md={4}>
              <div className="mb-3">
                <FormGroup className="form-group">
                   <AvField
                      type="select"
                      name="classID"
                      label="class"
                      value={feeObj.classID}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      errorMessage="please select class "
                      validate={{ required: { value: true } }}
                    >
                      <option vale="">Select Class</option>
                      {classesData?.map((classes) => (
                        <option value={classes._id}>{classes.className}</option>
                      ))}
                    </AvField>
                    </FormGroup>
                  
                </div>
              </Col>

              <Col md={4}>
              <div className="mb-3">
                <FormGroup className="form-group">
                   <AvField
                      type="select"
                      name="status"
                      label="fee status"
                      value={feeObj.status}
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      errorMessage="please select fee status "
                      validate={{ required: { value: true } }}
                    >
                      <option value="">Select Status</option>
                      <option value="due">due</option>
                      <option value="fullPaid">full paid</option>
                      <option value="percialPaid">percial paid</option>
                      <option value="unpaid">unPaid</option>
                      
                    </AvField>
                    </FormGroup>
                  
                </div>
              </Col>
              <Col md={2}>
              <div className="mb-3">
                    <Label>check out</Label>
                <FormGroup className="form-group">
                <Button
                    type="submit"
                   
                    style={{ background: "#0A928C", color: "white" }}
                    className=" dropdown-toggle waves-effect waves-light "
                  >
                    <i className="ion ion-md-add me-2"></i> check
                  </Button>
                    </FormGroup>
                  
                </div>
              </Col>
              <Col md={2}>
              <div className="mb-3">
                    <Label>Download List</Label>
                <FormGroup className="form-group">
                <CSVLink
                    filename={"TableContent.csv"}
                    data={exportData}
                    className="btn btn-success"
                  >
                    Download Excel
                  </CSVLink> 
                    </FormGroup>
                  
                </div>
              </Col>
            
            </Row>
            </AvForm>

            <Row>

            <MDBDataTable responsive striped bordered data={data} />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
          </div>
   

    </Container>
      </div>
    </React.Fragment>
  )


}

export default Feereport

