import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postfunc } from "../../services/mainService"

const StockReport = () => {
  const [statementObj, setstatementObj] = useState({
    type: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setloading] = useState(false)
  const [ReportData, setReportData] = useState([])
  const [statementTempData, setstatementTempData] = useState([])

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  useEffect(() => {
    const getReceipt = async () => {
      try {
        // const { data: receiptList } = await GetAll("transection", headers)
        // let checkTransection = Array.isArray(receiptList)
        // if (checkTransection) {
        //   let balalance = 0
        //   let statementData = receiptList?.map(item => {
        //     let obj = JSON.parse(JSON.stringify(item))
        //     obj.transectionType == "income"
        //       ? (balalance += obj.amount)
        //       : (balalance -= obj.amount)
        //     obj.runningBalance = balalance
        //     //   let newObject = {...obj, ...obj.customerID };
        //     return obj
        //   })
        // setstatementTempData(statementData)
        // ReportData(statementData)
        // }
      } catch (error) {
        console.log(error.message)
      }
    }
    getReceipt()
  }, [])

  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      setloading(true)
      const { data } = await postfunc(
        "reports/stockReport",
        { type: statementObj.type },
        headers
      )
      const { status, message, ReportData } = { ...data }
      if (status) {
        notify(message, "success")

        setReportData(ReportData)
      } else {
        notify(message, "error")
      }

      setloading(false)
    } catch (error) {
      setloading(false)
      notify(error.message, "error")
      console.log(error.message)
    }
  }
  const handleChange = e => {
    setstatementObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = ReportData?.map(cust => {
    const data = { ...cust }
    data.date = moment(data.date).format("lll")
    data.Amount = "$" + data.amount
    data.customerName = data.customerID?.name

    data.date = moment(data.createdAt).format("lll")
    const obj = {
      itemName: data.itemName,
      "quantity InStock ": data.quantityInStock,
      purchusePrice: data.purchusePrice,
      sellingPrice: data.sellingPrice,
      Date: data.Date,
    }

    return obj
  })
  const data = {
    columns: [
      {
        label: "Item Name",
        field: "itemName",
        sort: "asc",
        width: 150,
      },
      {
        label: "quantity InStock",
        field: "quantityInStock",
        sort: "asc",
        width: 270,
      },
      {
        label: "purchuse Price",
        field: "purchusePrice",
        sort: "asc",
        width: 270,
      },
      {
        label: "selling Price",
        field: "sellingPrice",
        sort: "asc",
        width: 270,
      },

      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 200,
      },
    ],
    rows: ReportData?.map(CurrentstockData => {
      let data = { ...CurrentstockData }
      data.Date = moment(data.createdAt).format("lll")
      data.sellingPrice = "$" + data.sellingPrice
      data.purchusePrice = "$" + data.purchusePrice

      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="easy soft"
              title="reports"
              breadcrumbItem="Service Report"
            />
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={6}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="type"
                                label="select type"
                                value={statementObj.type}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select type  "
                                validate={{ required: { value: true } }}
                              >
                                <option value="">Select type</option>
                                <option value="1">in the Stock</option>
                                <option value="2">Out Of the Stock</option>
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>View</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                {/* <i className="ion ion-md-add me-2"></i> */}
                                {loading ? "loading" : "View Report"}
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Download </Label>
                            <FormGroup className="form-group">
                              <CSVLink
                                filename={"StockReport.csv"}
                                data={exportData}
                                className="btn btn-success"
                              >
                                Download
                              </CSVLink>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StockReport
