import React, { createContext, useState, useEffect } from "react"
import moment from "moment"
import {
  GetAll,
  fetchAll,
  postfunc,
  putfunc,
  deletefunc,
} from "../services/mainService"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import jwtDecode from "jwt-decode"
import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import axios from "axios"
export const ExpenseContext = createContext()
ExpenseContext.displayName = "ExpenseContext"

const ExpenseContextProvider = props => {
  const [expenseObj, setexpenseObj] = useState({
    userID: "",
    transectionType: "",
    description: "",
    amount: "",
    date: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [brandType, setbrandType] = useState("")
  const location = useLocation()
  Auhtentication(location.pathname)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const getclass = async () => {
      try {
        const token = localStorage.getItem("token")
        const user = jwtDecode(token)
        setcurrentUser(user)
        let brandType = sessionStorage.getItem("brandType")
        setbrandType(brandType)
      } catch (error) {
        console.log(error.message)
      }
    }
    getclass()
  }, [])

  //   useSwr("branch",getMyprint);
  let { data: Transectionlist } = useSwr("transection", fetchAll)
  let TransectionData = []

  let chechArray = Array.isArray(Transectionlist)
  if (chechArray == true) {
    TransectionData = Transectionlist
  }

  const expenseData = TransectionData?.filter(
    i => i.transectionType == "expense"
  )
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setexpenseObj({
      userID: "",
      transectionType: "",
      description: "",
      amount: "",
      date: "",
    })
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {
        const { data } = await putfunc("transection", expenseObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("transection", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setexpenseObj({
            userID: "",
            transectionType: "",
            description: "",
            amount: "",
            date: "",
            name: "",
            type: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        let mainObj = {
          userID: currentUser._id,
          transectionType: "expense",
          amount: expenseObj.amount,
          date: expenseObj.date,
          description: expenseObj.description,
          name: expenseObj.name,
          type: expenseObj.type,
        }
        // return console.log("expenseObj", mainObj)

        const { data } = await postfunc("transection", mainObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("transection", fetchAll)
          setexpenseObj({
            userID: "",
            transectionType: "",
            description: "",
            amount: "",
            date: "",
            name: "",
            type: "",
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleChange = e => {
    setexpenseObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async transData => {
    let data = { ...transData }

    data.userID = data.userID?._id
    data.date = moment(data.datePaid).format("YYYY-MM-DD[T]HH:mm:ss")
    setEditCondition(true)
    setexpenseObj(data)
    setIsOpen(true)
  }
  const handleDelete = data => {
    // console.log(data)
    setexpenseObj(data)
    setsweetAlertConfirm(true)
  }
  return (
    <ExpenseContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        expenseData,
        editCondition,
        expenseObj,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        brandType,
      }}
    >
      {props.children}
    </ExpenseContext.Provider>
  )
}

export default ExpenseContextProvider
