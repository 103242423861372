import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  Row,
} from "reactstrap"
import useSWR, { mutate } from "swr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import "./datatables.scss"
import axios from "axios"
import { useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { useLocation } from "react-router-dom"
import Auhtentication from "../../authMidlleware/userMiddleWare"

function Stock() {
  const [isopen, setisopen] = useState(false)
  const [isupdate, setisupdate] = useState(false)
  const [updateopen, setupdateopen] = useState(false)
  const [loading, setloading] = useState(false)
  const tog_standard = () => {
    setisopen(!isopen)
    setisupdate(false)
    if (!isupdate) {
      setstockObj("")
    }
  }
  const update_tog = () => {
    setupdateopen(!updateopen)
  }
  const location = useLocation()
  Auhtentication(location.pathname)
  const token = sessionStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  let url = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(url + "/" + path, headers)
    return data
  }

  let drugData = []

  let { data: drugList } = useSWR("stock", fetchAll)

  let chechArray = Array.isArray(drugList)

  if (chechArray) {
    drugData = drugList
  }
  const [stockObj, setstockObj] = useState({})

  // tiket post
  const handlePost = async (event, value) => {
    try {
      setloading(true)
      if (isupdate) {
        console.log("stockObj", stockObj)
        const { data } = await axios.put(
          `${url}/stock/${stockObj._id}`,
          stockObj
        )

        if (data.status) {
          setisopen(false)
          setisupdate(false)
          mutate(`stock`, fetchAll)
          toast.success(data.message)
        } else {
          toast.error(data.message)
        }
        setloading(false)
      } else {
        const { data } = await axios.post(`${url}/stock`, value, headers)

        if (data.status) {
          toast.success(data.message)
          setisopen(false)
          mutate(`stock`, fetchAll)
        } else {
          toast.success(data.message)
        }
        setloading(false)
      }
      //   if(!isupdate){
      // setstockObj([])
    } catch (error) {
      toast.error(error.message)
      setloading(false)
    }
    //   }
  }
  // put or update
  const [updateid, setupdateid] = useState([])
  const [Date, setDate] = useState("")

  const handleEdit = updatadr => {
    setisupdate(true)
    setisopen(!isopen)
    setupdateid(updatadr._id)
    updatadr.Date = moment(updatadr.Date).format("YYYY-MM-DD")

    setstockObj(updatadr)
    // setDate(updatadr.DateofBirth)
    console.log(updatadr)
  }

  const [deletedId, setdeletedId] = useState([])
  const [deletealert, setdeletealert] = useState(false)

  const handledoctordelete = deletedata => {
    setdeletedId(deletedata._id)
    setdeletealert(true)
  }
  const handleDelete = async () => {
    // console.log("delte")
    try {
      const { data } = await axios.delete(`${url}/stock/${deletedId}`, headers)
      setdeletealert(false)
      if (data.status === "success") {
        toast.success(data.message)
        mutate(`stock`, fetchAll)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleChange = e => {
    setstockObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  //get data pataint
  const [getdoctordata, setgetdoctordata] = useState([])
  //   console.log(getdoctordata)

  const [getpatiant, setgetpatiant] = useState([])
  //   console.log(getpatiant)
  useEffect(() => {
    async function onload() {
      const { data } = await axios.get(`${url}/patient`, headers)
      if (Array.isArray(data)) {
        setgetpatiant(data)
      }
    }
    onload()
  }, [])

  const data = {
    columns: [
      {
        label: "Item Name",
        field: "itemName",
        sort: "asc",
        width: 150,
      },
      {
        label: "quantity InStock",
        field: "quantityInStock",
        sort: "asc",
        width: 270,
      },
      {
        label: "purchuse Price",
        field: "purchusePrice",
        sort: "asc",
        width: 270,
      },
      {
        label: "selling Price",
        field: "sellingPrice",
        sort: "asc",
        width: 270,
      },

      // {
      //   label: "quantity Sold",
      //   field: "QuantitySold",
      //   sort: "asc",
      //   width: 270,
      // },

      // {
      //   label: "Price",
      //   field: "amount",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: drugData?.map(CurrentstockData => {
      let data = { ...CurrentstockData }
      data.Date = moment(data.createdAt).format("lll")
      data.sellingPrice = "$" + data.sellingPrice
      data.purchusePrice = "$" + data.purchusePrice
      data.action = (
        <div className="d-flex">
          <button
            className="btn   text-primary "
            onClick={() => handleEdit(CurrentstockData)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn btn-white  text-danger  mx-3 "
            onClick={() => handledoctordelete(CurrentstockData)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Software Decoration"
            title="Inventory"
            breadcrumbItem="Stock"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={10}></Col>
                    {/* <CardTitle className="h4">Stock Table </CardTitle> */}

                    <Col md={2}>
                      <button
                        type="button"
                        onClick={() => {
                          tog_standard()
                        }}
                        className="btn waves-effect waves-light float-end mb-3"
                        style={{
                          backgroundColor: "#607d8e",
                          color: "white",
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Add New Item
                      </button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isopen}
            togle={() => {
              tog_standard()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                stock items
              </h5>
            </div>
            <AvForm
              onValidSubmit={(e, v) => {
                handlePost(e, v)
              }}
            >
              <div className="modal-body">
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label> Item Name </Label>
                      <AvField
                        name="itemName"
                        placeholder="Item Name"
                        type="text"
                        value={stockObj.itemName}
                        onChange={e => handleChange(e)}
                        errorMessage="Enter Drug Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label> quantityInStock </Label>
                      <AvField
                        name="quantityInStock"
                        placeholder="quantity"
                        type="text"
                        value={stockObj.quantityInStock}
                        onChange={e => handleChange(e)}
                        errorMessage="Enter quantity"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md="6">
                    <FormGroup className="mb-3">
                      <Label> Amount </Label>
                      <AvField
                        name="Amount"
                        placeholder="Amount"
                        type="text"
                        value={stockObj.Amount}
                        errorMessage="Enter Amount"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label> purchusePrice </Label>
                      <AvField
                        name="purchusePrice"
                        placeholder="purchuse Price"
                        type="text"
                        value={stockObj.purchusePrice}
                        onChange={e => handleChange(e)}
                        errorMessage="Enter purchuse Price"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label> sellingPrice </Label>
                      <AvField
                        name="sellingPrice"
                        placeholder="sellingPrice"
                        type="text"
                        value={stockObj.sellingPrice}
                        onChange={e => handleChange(e)}
                        errorMessage="Enter sellingPrice"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  {loading ? "saving..." : "Save Item"}
                </button>
              </div>
            </AvForm>
          </Modal>
          <ToastContainer />
          {deletealert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="be carefull please before deleting"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handleDelete}
              onCancel={() => setdeletealert(false)}
            >
              be carefull please before deleting
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>

    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>Customer | </title>
    //     </MetaTags>
    //     <Container fluid>
    //       <div className="page-title-box">
    //         <Row className="align-items-center">
    //           <h1>Customer component</h1>
    //         </Row>
    //       </div>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default Stock
