import React,{useEffect} from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import jwtDecode from "jwt-decode"
import {Auth_middleware} from "../../authMidlleware/authMiddleware"

const token=sessionStorage.getItem("token")
let user;
if(token!==null){
  user = jwtDecode(token);
}


const Authmiddleware = ({component: Component,layout: Layout,isAuthProtected,...rest}) => (
  
  <Route
    {...rest}
    render={props => {
    //  let token= localStorage.getItem("token")
    //   const user = jwtDecode(token);
      

      if (isAuthProtected && !sessionStorage.getItem("token")) {
    
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
