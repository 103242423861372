import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import CoursesTable from "./coursesTable"
import CourseForm from "./courseForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CourseContextProviderr from "../../context/coursesContext"

const Courses = () => {
  return (
    <React.Fragment>
      <CourseContextProviderr>
      <div className="page-content">
        <MetaTags>
          <title>
            Courses | easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="class" breadcrumbItem="class" />
             <CourseForm/>
              <CoursesTable/>
          </div>
        </Container>
      </div>
      </CourseContextProviderr>
    </React.Fragment>
  )
}

export default Courses
