import React, { useState,useContext } from "react"
import MetaTags from "react-meta-tags"
import useSwr,{mutate,trigger,useSWRConfig} from "swr"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,

} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ToastContainer, toast } from "react-toastify";
import {ExpenseContext} from "../../context/expenseContext"
import 'react-toastify/dist/ReactToastify.css';
const ExpenseTable = () => {
  const { isOpen,editCondition,
    expenseData, 
    tog_standard, 
    handleEdit ,
    setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        expenseObj
  } =
  useContext(ExpenseContext);
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token
    },
  };
  const data = {
    columns: [
        {
            label: "No",
            field: "transectionNo",
            sort: "asc",
            width: 50,
          },
      {
        label: "transectionType",
        field: "transectionType",
        sort: "asc",
        width: 270,
      },
      {
        label: "ExpenseType",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "name",
        field: "name",
        sort: "asc",
        width: 270,
      },
     
      {
        label: "amount",
        field: "incomeAmount",
        sort: "asc",
        width: 100,
      },
      {
        label: "description",
        field: "description",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "incomedate",
        sort: "asc",
        width: 100,
      },
      {
        label: "User Register",
        field: "userName",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: expenseData?.map(hos => {
      const data = { ...hos }     
      data.incomedate = moment(data.date).format("lll")
      data.incomeAmount="$" + data.amount
      data.userName = data.userID?.name;

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button onClick={() => handleDelete(data)}   className="btn btn-white btn-sm mr-4">
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
          {sweetAlertConfirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={async () => {
              try {
              // return  console.log(IncomeObj)
                const { data } = await deletefunc("transection", expenseObj,headers)
                const { status, message } = { ...data }
                if (status) {
                  toast.success(message)
                  setsweetAlertConfirm(false)
                  mutate("transection", fetchAll)
                } else {
                  toast.error(message)
                }
              } catch (error) {
                toast.error(error.message)
              }
            }}
            onCancel={() => setsweetAlertConfirm(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Expense Table </CardTitle>
              </Col>
              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Expense
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ExpenseTable
