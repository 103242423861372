import React, { useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { subscriptionContext } from "../../context/subscriptionContext"

const SubscriptionForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    disabled,
    load,
    susbcriptionObj,
  } = useContext(subscriptionContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Create Subscription</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="subscriptoinTerm"
                      type="select"
                      label="Subscription Term"
                      value={susbcriptionObj.subscriptoinTerm}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  subscriptoin Term"
                      errorMessage="Enter subscriptoin Term"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">Select Subscription Term</option>

                      <option value="OneMonth">OneMonth</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="amountPaid"
                      type="text"
                      label="amount"
                      value={susbcriptionObj.amountPaid}
                      onChange={e => handleChange(e)}
                      placeholder="Enter amount Paid"
                      errorMessage="Enter amount Paid"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="accountType"
                      type="select"
                      label="accountType"
                      value={susbcriptionObj.accountType}
                      onChange={e => handleChange(e)}
                      placeholder="Enter account Type"
                      errorMessage="Enter account Type"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">Select account Type</option>
                      <option value="evc">Evc</option>
                      <option value="zaad">Zaad</option>
                      <option value="e-dahab">E-dahab</option>
                    </AvField>
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="PhonePaid"
                      type="number"
                      label="Phone Paid"
                      value={susbcriptionObj.PhonePaid}
                      onChange={e => handleChange(e)}
                      placeholder="EnterPhone Paid"
                      errorMessage="Enter PhonePaid"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="datePaid"
                      type="datetime-local"
                      label="date Paid"
                      value={susbcriptionObj.datePaid}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  datePaid"
                      errorMessage="Enter datePaid"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="expiredDate"
                      type="datetime-local"
                      label="date Expired Date"
                      value={susbcriptionObj.expiredDate}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  expired Date"
                      errorMessage="Enter expired Date"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                type="submit"
                className="waves-effect waves-light"
              >
                {load ?"saving...":" Save Subscription"}
               
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default SubscriptionForm
