import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "../Dashboard/datatables.scss"
import UserTable from "./usesTable"
import UsersForm from "./usersForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UsersContextProvider from "../../context/userContext"

const Users = () => {
  return (
    <React.Fragment>
      <UsersContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Software | Users</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="software"
                title="users"
                breadcrumbItem="users"
              />
              <UsersForm />
              <UserTable />
            </div>
          </Container>
        </div>
      </UsersContextProvider>
    </React.Fragment>
  )
}

export default Users
