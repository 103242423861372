import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "../Dashboard/datatables.scss"
import EmployeeTable from "./employeeTable"
import EmployeeForm from "./employeeForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmployeeContextProvider from "../../context/employeeContext"

const Courses = () => {
  return (
    <React.Fragment>
      <EmployeeContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Software | Employee</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Software"
                title="Hrm"
                breadcrumbItem="Employee"
              />
              <EmployeeForm />
              <EmployeeTable />
            </div>
          </Container>
        </div>
      </EmployeeContextProvider>
    </React.Fragment>
  )
}

export default Courses
