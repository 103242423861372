import React, { useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  CardHeader,
  CardSubtitle,
  Collapse,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../Dashboard/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { enrollmentContext } from "../../context/enrollmentContext"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik"
import Enrollment from './enrollemntIndex';
const EnrollmentForm = () => {
 
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleEdit,
    handleChange,
    CourseONChenge,
    HandleSubmitBtn,
    Students,
    coursesData,
    classData,
    enrollmentObj,
    StudentIDOnachnge,
    studentID,
    classTemp,
    setclassData,
    initialValues,
    handleSubmit,
    collapseToggle,
    Icon,
    setcollapse,
    collapse,
    disabled,
    load,
    setEditCondition
  } = useContext(enrollmentContext)

  
  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="bg-white">

              <Button style={{backgroundColor:"#14213D", color:'white'}} onClick={collapseToggle}>
                Add Enrollment
              </Button>
            </CardHeader>

            <Collapse isOpen={collapse}>
              <CardBody>
                {/* <CardTitle className="mb-4">Invoice</CardTitle> */}

                <Formik
                  enableReinitialize={true}
                  initialValues={
                    editCondition
                      ? {
                          _id: enrollmentObj._id,
                         
                          studentID:enrollmentObj.studentID?._id,
                          courseID: enrollmentObj.courseID?._id,
                          classID: enrollmentObj.classID?._id,
                          Amount: enrollmentObj.Amount,
                          enrolledStatus: enrollmentObj.enrolledStatus,
                          items: [
                            {
                              feeName: "",
                              feetype: "",
                              feeAmount: 0,
                            },
                          ],
                         
                        }
                      : initialValues
                  }
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                      <Row className="mb-4">
                        <Col lg={3} className="mb-3">
                          <FormGroup>
                            <Label>student name</Label>
                            <Select
                              name="studentID"
                              placeholder="select student "
                              required
                              onChange={event => {
                                setFieldValue(`studentID`, event.value)
                              }}
                              options={Students?.map((student, index) => {
                                return {
                                  label: student.studentName+"-"+student.stdID,
                                  value: student._id,
                                  key: index,
                                }
                              })}
                            ></Select>
                          </FormGroup>

                          {errors.studentID && touched.studentID ? (
                            <span className="text-danger">
                              {errors.studentID}
                            </span>
                          ) : null}
                        </Col>
                        <Col lg={3} className="mb-3">
                          <FormGroup>
                            <Label>select course</Label>
                            <Select
                              name="courseID"
                              placeholder="select course "
                              required
                              onChange={event => {
                                setFieldValue(`courseID`, event.value)
                                const courseClass = classTemp?.filter(
                                  i =>
                                    i.courseID?._id == event.value &&
                                    i.classStatus == "active"
                                )
                                // console.log(courseClass)
                                setclassData(courseClass);

                                const courseobj = coursesData.find(
                                  item => item._id == event.value
                                )
                                setFieldValue(`Amount`, courseobj.coursePrice)

                              }}
                              options={coursesData?.map((course, index) => {
                                return {
                                  label: course.courseName,
                                  value: course._id,
                                  key: index,
                                }
                              })}
                            ></Select>
                          </FormGroup>

                          {errors.studentID && touched.studentID ? (
                            <span className="text-danger">
                              {errors.studentID}
                            </span>
                          ) : null}
                        </Col>
                        <Col lg={3} className="mb-3">
                          <FormGroup>
                            <Label>select class</Label>
                            <Select
                              name="classID"
                              placeholder="select class "
                              required
                              onChange={event => {
                                setFieldValue(`classID`, event.value)
                              }}
                              options={classData?.map((clas, index) => {
                                return {
                                  label: clas.className,
                                  value: clas._id,
                                  key: index,
                                }
                              })}
                            ></Select>
                          </FormGroup>

                          {errors.studentID && touched.studentID ? (
                            <span className="text-danger">
                              {errors.studentID}
                            </span>
                          ) : null}
                        </Col>
                        <Col lg={3} className="mb-3">
                          <label htmlFor="">Amount </label>
                          <Field
                            type="number"
                            name="Amount"
                            // required
                            className="form-control"
                            placeholder="Enter Amount"
                          ></Field>
                          {errors.qndate && touched.qndate ? (
                            <span className="text-danger">{errors.qndate}</span>
                          ) : null}
                        </Col>
                        {editCondition && (
                          <Col lg={3} className="mb-3">
                          <label htmlFor="">Status </label>
                          <Field
                            as="select"
                            name="enrolledStatus"
                          
                
                            className="form-control"
                            placeholder="Enter Amount"
                          >
                                   <option value="">
                                          select status
                                        </option>
                                        <option value="active">
                                          Active
                                        </option>
                                        <option value="pending">
                                          Pending
                                        </option>
                                        <option value="waiting">
                                        waiting
                                        </option>
                                        <option value="blocked">
                                        blocked
                                        </option>
                                        <option value="graduated">
                                        graduated
                                        </option>
                          </Field>
                         
                        </Col>

                        )} 
                        
                      </Row>
                      

                      <FieldArray name="items">
                        {({ insert, remove, push }) => (
                          <div>
                            {values.items?.length > 0 &&
                              values.items?.map((item, index) => (
                                <>
                                  <Row key={index}>
                                    <Col lg={3} className="mb-3">
                                      <Field
                                        as="select"
                                        name={`items.${index}.feetype`}
                                        required
                                        onChange={event => {
                                          setFieldValue(`items.${index}.feetype`, event.target.value)
                                          if(event.target.value=="monthlyfee"){
                                            setFieldValue(`items.${index}.feeAmount`, values.Amount)
                                          }else{
                                            setFieldValue(`items.${index}.feeAmount`, 0)
                                          }
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                      >
                                        <option value="">
                                          Choose fee type
                                        </option>
                                        <option value="monthlyfee">
                                          monthly fee{" "}
                                        </option>
                                        <option value="Registrationfee">
                                          Registration Fee{" "}
                                        </option>
                                        <option value="workshop">
                                          Work shop fee{" "}
                                        </option>
                                        <option value="certificate">
                                          certificate fee{" "}
                                        </option>
                                        <option value="IDcard">
                                          ID card fee{" "}
                                        </option>
                                        <option value="others">
                                          Others fee{" "}
                                        </option>
                                        {/* {classData?.map((course) => (
                                    <option value={course._id}>
                                      {course.className}
                                    </option>
                                  ))} */}
                                      </Field>
                                    </Col>

                                    <Col lg={3} className="mb-3">
                                      <Field
                                        // required
                                        name={`items.${index}.feeName`}
                                        // value={gerserviceprice(item.serviceID)}
                                        className="form-control"
                                        placeholder="Enter fee name"
                                        type="text"
                                      />
                                    </Col>
                                    <Col lg={3} className="mb-3">
                                      <Field
                                        // required
                                        name={`items.${index}.feeAmount`}
                                        // value={gerserviceprice(item.serviceID)}
                                        className="form-control"
                                        placeholder="Enter fee amount"
                                        type="number"
                                      />
                                    </Col>

                                    <Col lg={2} className="mb-3">
                                      <button
                                        type="button"
                                        color="danger"
                                        className="dripicons-trash btn btn-danger"
                                        onClick={() => remove(index)}
                                      >
                                        <i className=" ri-delete-bin-line"></i>
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-dark mx-2"
                                        onClick={() =>
                                          push({
                                            feeName: "",
                                            feetype: "",
                                            feeAmount: 0,
                                          })
                                        }
                                      >
                                        Add Fee
                                      </button>
                                    </Col>
                                  </Row>
                                  <hr />
                                </>
                              ))}
                          </div>
                        )}
                      </FieldArray>

                      {/* <hr /> */}
                      <Button
                        type="submit"
                        // color="warning"
                        disabled={disabled}
                        className="btn-rounded mb-2  me-2 btn btn-success float-right "
                      >
                        <i className="mdi mdi-plus mr-2"></i> 
                        {load ?"saving...":" Save Enrollment"}
                       
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        disabled={disabled}
                        onClick={()=>{
                          setcollapse(false)
                          setEditCondition(false)
                          
                        }}
                        className="btn-rounded mb-2  me-2 btn btn-success float-right "
                      >
                   
                        Close
                       
                      </Button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={6} md={4} xl={3} className="mt-4">
          <div className="text-center"></div>

          <Modal isOpen={isOpen} toggle={tog_standard}>
            <ModalHeader
              style={{
                background: "#14213D",
                color: "white",
                border: "#0A928C",
              }}
              toggle={tog_standard}
            >
              {" "}
              <p>Create Enrollment </p>
            </ModalHeader>
            <AvForm
              className="needs-validation"
              onValidSubmit={HandleSubmitBtn}
            >
              <ModalBody>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                    
                      <Label>Select student</Label>
                      <Select
                        name="studentID"
                        value={studentID}
                        // isClearable={true}
                        isSearchable={true}
                        onChange={e => StudentIDOnachnge(e)}
                        options={Students?.map((student, index) => {
                          return {
                            label: student.studentName + "-" + student.stdID,
                            value: student._id,
                            key: index,
                          }
                        })}
                      ></Select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        type="select"
                        name="courseID"
                        label="Course"
                        value={enrollmentObj.courseID}
                        onChange={e => CourseONChenge(e)}
                        className="form-control "
                        errorMessage="please select course"
                        validate={{ required: { value: true } }}
                      >
                        <option vale="">Select course</option>
                        {coursesData?.map(course => (
                          <option value={course._id}>
                            {course.courseName}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        type="select"
                        name="classID"
                        label="class"
                        value={enrollmentObj.classID}
                        onChange={e => handleChange(e)}
                        className="form-control "
                        errorMessage="please select class "
                        validate={{ required: { value: true } }}
                      >
                        <option vale="">Select Class</option>
                        {classData?.map(classes => (
                          <option value={classes._id}>
                            {classes.className}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        name="Amount"
                        type="text"
                        label="Amount"
                        value={enrollmentObj.Amount}
                        onChange={e => handleChange(e)}
                        placeholder="Enter Amount"
                        errorMessage="Enter Amount"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        name="enrolledDate"
                        type="date"
                        label="enrolled Date"
                        value={enrollmentObj.enrolledDate}
                        onChange={e => handleChange(e)}
                        placeholder="Enter enrolled Date"
                        errorMessage="Enter enrolledDate"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    <div className="mb-3">
                      <AvField
                        name="enrolledStatus"
                        type="select"
                        label="enrolled Status"
                        value={enrollmentObj.enrolledStatus}
                        onChange={e => handleChange(e)}
                        placeholder="Enter enrolled Status"
                        // errorMessage="Enter enrolled Status"
                        // validate={{
                        //   required: { value: true },
                        // }}
                      >
                        <option vlaue="">Select Status </option>
                        <option vlaue="active">active</option>
                        <option vlaue="pending">pending</option>
                        <option vlaue="graduated">graduated</option>
                        <option vlaue="blocked">blocked</option>
                      </AvField>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="danger"
                  onClick={tog_standard}
                  className="waves-effect"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  className="waves-effect waves-light"
                >
                  Save Enrollment
                </Button>
              </ModalFooter>
            </AvForm>
          </Modal>
        </Col>
        <ToastContainer />
      </Row>
    </div>
  )
}

export default EnrollmentForm
