import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { GetAll, fetchAll, postfunc, putfunc } from "../../services/mainService"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import moment from "moment"
// Custom Scrollbar
import SimpleBar from "simplebar-react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
// import Apexdonut from "../AllCharts/apex/apexdonut1";
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"
import { MDBDataTable } from "mdbreact"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import Auhtentication from "../../authMidlleware/userMiddleWare"

const DecorationDashboard = props => {
  const [menu, setMenu] = useState(false)
  const [latestTransections, setlatestTransections] = useState([])
  const [summeryData, setsummeryData] = useState({
    NumberOfstudents: 0,
    NumberOfEnrolledStudents: 0,
    NumberOfUsers: 1,
    NumberOfTeachers: 0,
    NumberOfTransections: 0,
    NumberOfClasses: 0,
    NumberOfCourses: 0,
    NumberOfEmployee: 0,
    totalReceiptMoney: 0,
    totalincome: 0,
    totalExpense: 0,
    totalDue: 0,
    balance: 0,
    currentMonthIncome: 0,
    currentMonthExpense: 0,
  })
  const [enrollmentData, setenrollmentData] = useState([])
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  useEffect(() => {
    const getclass = async () => {
      try {
        const { data: latestTransection } = await GetAll(
          "statement/latest",
          headers
        )

        const { data: dashboardSummry } = await GetAll("summary", headers)

        let chechArray = Array.isArray(latestTransection)
        if (chechArray == true) {
          setlatestTransections(latestTransection)
        }

        let chechSummary = Object.keys(dashboardSummry).length

        if (chechSummary > 2) {
          setsummeryData(dashboardSummry)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getclass()
  }, [])

  const latestStatemens = latestTransections?.map(latestTrans => {
    const data = { ...latestTrans }
    data.username = data.userID?.name
    data.amount = "$" + data.amount
    data.date = moment(data.createdAt).format("lll")

    if (data.transectionType == "income") {
      data.status = (
        <div className="badge bg-success  font-size-12 text-white badge-pill mr-2">
          {data.transectionType}
        </div>
      )
    } else {
      data.status = (
        <div className="badge bg-danger font-size-12  text-white badge-pill mr-2">
          {data.transectionType}
        </div>
      )
    }

    return data
  })

  const toggle = () => {
    setMenu(!menu)
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  console.log("enrollmentData", enrollmentData)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Decoration | Dashboard</title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box">
            {/* <Breadcrumbs maintitle="Veltrix" title="Dashboard" breadcrumbItem="Dashboard" /> */}
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#14213D" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Income
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {"$" + summeryData?.totalincome.toFixed(2)}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">over all</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#14213D" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Expenses
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {"$" + summeryData?.totalExpense.toFixed(2)}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">over all</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card
                className="mini-stat  text-white"
                style={{ backgroundColor: "#14213D" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Balance
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {"$" + summeryData?.balance.toFixed(2)}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-info">
                      <p className="mb-0"> 00%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">over all</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card
                className="mini-stat text-white"
                style={{ backgroundColor: "#14213D" }}
              >
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Depts
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {"$" + summeryData?.totalDue.toFixed(2)}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">over all</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={9}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Monthly Earning</h4>
                  <Row>
                    <Col lg={7}>
                      <div>
                        <LineAreaChart />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">This month Income</p>
                            <h3>
                              {"$" + summeryData?.currentMonthIncome.toFixed(2)}
                            </h3>
                            <p className="text-muted mb-5">
                              {`This is the ${moment().format(
                                "MMMM"
                              )} incomes `}
                            </p>
                            <RadialChart />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">
                              This month Expense
                            </p>
                            <h3>
                              {"$" +
                                summeryData?.currentMonthExpense.toFixed(2)}
                            </h3>
                            <p className="text-muted mb-5">
                              {`This is the ${moment().format(
                                "MMMM"
                              )} expenses you are entered`}
                            </p>
                            {/* <Apexdonut /> */}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3}>
              <Card>
                <CardBody>
                  <div>
                    <h4 className="card-title mb-4">Summary Report</h4>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Users</p>
                          <h5 className="mb-4">
                            {" "}
                            {summeryData?.NumberOfUsers}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Employees</p>
                          <h5 className="mb-4">
                            {" "}
                            {summeryData?.NumberOfEmployee}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine1 />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Transectoins</p>
                          <h5> {summeryData?.NumberOfTransections}</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Transaction</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) Id</th>
                          <th scope="col">Transection Type</th>
                          <th scope="col">Category</th>
                          <th scope="col">Name</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Date</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestStatemens?.map(item => (
                          <tr>
                            <td>{item.transectionNo}</td>
                            <td>{item.transectionType}</td>
                            <td>{item.type}</td>
                            <td>{item.name}</td>
                            <td>{item.amount}</td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DecorationDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DecorationDashboard)
