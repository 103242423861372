import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {Container} from "reactstrap"
import "../Dashboard/datatables.scss"
import SubscriptionTable from "./subscriptionTable"
import SubscriptionForm from "./subscriptionForm"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SubscriptionContextProvider from "../../context/subscriptionContext"

const Subscription = () => {
  return (
    <React.Fragment>
      <SubscriptionContextProvider>
      <div className="page-content">
        <MetaTags>
          <title>
            Subscription | easy soft
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs maintitle="Veltrix" title="class" breadcrumbItem="class" />
             <SubscriptionForm/>
              <SubscriptionTable/>
          </div>
        </Container>
      </div>
      </SubscriptionContextProvider>
    </React.Fragment>
  )
}

export default Subscription
