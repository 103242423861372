import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
// action
import { apiError, registerUser, registerUserFailed } from "../../store/actions"

// Redux
import ReactEcharts from "echarts-for-react"
import jwtDecode from "jwt-decode"
import { connect } from "react-redux"
import { GetAll } from "../../services/mainService"
// import images
import moment from "moment"
import { CSVLink } from "react-csv"
import { ShimmerCategoryList, ShimmerTitle } from "react-shimmer-effects"
import fullLogo from "../../assets/images/softwarelogo.png"
const ViewAttendence = props => {
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  const [currentStudent, setcurrentStudent] = useState({})
  const [studentclasses, setstudentclasses] = useState([])
  const [marks, setmarks] = useState([])
  const [attendence, settempAttendence] = useState([])
  const [AttendenceList, setAttendenceList] = useState([])

  const [subjects, setsubjects] = useState([])
  const [summary, setsummary] = useState({
    total: 0,
    average: 1,
    classPosittion: 0,
  })
  const [chartsummary, setchartsummary] = useState({
    totalAttended: 0,
    upsent: 0,
    present: 0,
  })
  const [showShimmer, setshowShimmer] = useState(true)
  const token = localStorage.getItem("studentToken")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  useEffect(() => {
    async function load() {
      try {
        const studentToken = localStorage.getItem("studentToken")
        if (studentToken) {
          let student = jwtDecode(studentToken)

          setcurrentStudent(student.studentInfo)
          setusername(student.name)
          setstudentclasses(student.studentClasses)
          let { data } = await GetAll("attendence", headers)
          //  console.log("data",data)
          //  return
          let checkAttendence = Array.isArray(data)
          if (checkAttendence) {
            settempAttendence(data)
          }
          let classLength = student.studentClasses.length
          if (classLength > 0) {
            let firstClassStd = student.studentClasses[0]
            //   console.log("fistClassStd",firstClassStd)
            let Attendences = []
            if (checkAttendence) {
              Attendences = data
            }
            let StudentAttendence = Attendences?.filter(
              att =>
                att.studentID?._id == student.studentInfo._id &&
                att.classID._id == firstClassStd._id
            )
            // console.log("StudentAttendence", StudentAttendence)

            setAttendenceList(StudentAttendence)

            let totalAtt = StudentAttendence.length

            let totalpresent = StudentAttendence.reduce(
              (total, item) => total + item.attended,
              0
            )
            let totalunpsent = totalAtt - totalpresent
            // console.log(totalAtt,totalpresent,totalunpsent)
            setchartsummary({
              totalAttended: totalAtt,
              present: totalpresent,
              upsent: totalunpsent,
            })
          }

          setshowShimmer(false)
        }
      } catch (error) {
        console.log(error)
        setshowShimmer(false)
      }
    }
    load()
  }, [])

  const handlechnage = e => {
    try {
      console.log(e.target.value)
      let StudentAttendence = attendence?.filter(
        att =>
          att.studentID?._id == currentStudent._id &&
          att.classID._id == e.target.value
      )
      console.log("StudentAttendence", StudentAttendence)

      setAttendenceList(StudentAttendence)

      let totalAtt = StudentAttendence.length

      let totalpresent = StudentAttendence.reduce(
        (total, item) => total + item.attended,
        0
      )
      let totalunpsent = totalAtt - totalpresent
      // console.log(totalAtt,totalpresent,totalunpsent)
      setchartsummary({
        totalAttended: totalAtt,
        present: totalpresent,
        upsent: totalunpsent,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const studentToken = localStorage.getItem("studentToken")
  const studentTokenSession = sessionStorage.getItem("studentToken")
  const history = useHistory()
  if (!studentTokenSession) {
    history.push(`/loginportal`)
  }
  const handleLogOut = () => {
    localStorage.removeItem("studentToken")
    sessionStorage.removeItem("studentToken")
    history.push(`/loginportal`)
  }

  const hanldeRedirect = () => {
    window.open("http://zaki.ezyro.com/?i=1", "_blank")
  }
  const data = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },

      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },

      {
        label: "Date Attended",
        field: "Date",
        sort: "asc",
        width: 100,
      },
      {
        label: "class Day",
        field: "day",
        sort: "asc",
        width: 100,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
    ],
    rows: AttendenceList?.map(hos => {
      const data = { ...hos }

      data.studentName = data.studentID?.studentName
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className
      data.teacherName = data.teacherID?.teacherName
      data.username = data.userID?.name
      data.Date = moment(data.createdAt).format("lll")

      if (data.attendenceStatus == "present") {
        data.status = (
          <div className="badge bg-success  font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      } else {
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      }

      return data
    }),
  }
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["present", "upsent"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#ec4561"],
      series: [
        {
          name: "attendece",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "10",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: chartsummary.present, name: "present" },
            { value: chartsummary.upsent, name: "upsent" },
          ],
        },
      ],
    }
  }
  const exportData = AttendenceList?.map(cust => {
    const data = { ...cust }
    data.studentName = data.studentID?.studentName
    data.stdID = data.studentID?.stdID
    data.courseName = data.courseID?.courseName
    data.className = data.classID?.className

    data.Date = moment(data.createdAt).format("lll")

    data.date = moment(data.createdAt).format("lll")
    const obj = {
      "student name": data.studentName,
      "student ID": data.stdID,
      courseName: data.courseName,
      className: data.className,

      "date Attended": data.date,
      Day: data.day,
      Status: data.attendenceStatus,
    }

    return obj
  })
  return (
    <React.Fragment>
      <MetaTags>
        <title>portal | easy soft</title>
      </MetaTags>
      <div style={{ backgroundColor: "#D8E9F9" }}>
        <div className="container">
          <Row>
            <Col className="mt-3 col-md-9">
              <img src={fullLogo} height="48" width={170} alt="logo" />
            </Col>
            <Col className="mt-3 col-md-3 " color="">
              {/* <Button onClick={()=>handleLogOut()} style={{backgroundColor:"#E27356"}} className="  text-white float-right">
                <i className="ion ion-md-log-out"></i> Logout
              </Button> */}
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item waves-effect pl-4"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  {/* <img
            className="rounded-circle header-profile-user pr-4"
            src={profileImage}
            alt="Header Avatar"
          />   */}
                  <span style={{ color: "#14213D" }}>{username}</span>
                  <span
                    style={{ color: "#14213D" }}
                    className="mdi mdi-chevron-down "
                  ></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end ">
                  <Link to="/portal" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewMarks</span>
                  </Link>

                  <Link to="/viewAttendence" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewAttendence</span>
                  </Link>
                  <Link to="/studentComplain" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>Complain</span>
                  </Link>

                  <div className="dropdown-divider" />
                  <Button
                    onClick={() => handleLogOut()}
                    className="dropdown-item"
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>Logout</span>
                  </Button>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>

      <div className="account-pages py-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#14213D" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Student Information
                    </h5>
                    <p className="text-white-50">Get your attendence.</p>
                    {/* <a href="index.html" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </a> */}
                  </div>
                </div>

                <CardBody className="p-4">
                  <div
                    class="row"
                    style={{ padding: "10px" }}
                    bis_skin_checked="1"
                  >
                    <div class="col-md-6" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 600 }}> Name: </b>
                        <span id="lbl_name">{currentStudent?.studentName}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}> Student ID: </b>
                        <span id="lbl_student_id">{currentStudent?.stdID}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Phone: </b>
                        <span id="lbl_faculty">
                          {currentStudent?.studentPhone}
                        </span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Address: </b>
                        <span id="lbl_department">
                          {currentStudent?.studentAddress}
                        </span>
                      </p>

                      {/* <p>
                        <b style={{ fontWeight: 600 }}>Current class: </b>
                        <span id="lbl_current_semester">8</span>
                      </p> */}
                    </div>
                    {/* <div class="col-md-3" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 600 }}>Class position: </b>
                        <span id="lbl_rank">#6</span> of{" "}
                        <b
                          style={{ fontWeight: 600 }}
                          id="lbl_number_of_students"
                        >
                          61
                        </b>{" "}
                        students
                      </p>
                    </div> */}
                    {showShimmer ? (
                      <ShimmerTitle
                        title
                        text
                        cta
                        thumbnailWidth={370}
                        thumbnailHeight={370}
                      />
                    ) : (
                      <Row className="">
                        <Col md={4}>
                          <p>
                            <b style={{ fontWeight: 600 }}>total periods: </b>
                            <span className="font-size-30">
                              {chartsummary.totalAttended}
                            </span>
                          </p>
                        </Col>
                        <Col md={4}>
                          <p>
                            <b style={{ fontWeight: 600 }}>Present periods: </b>
                            <span id="lbl_class">{chartsummary.present}</span>
                          </p>
                        </Col>
                        <Col md={4}>
                          <p>
                            <b style={{ fontWeight: 600 }}>upsent periods: </b>
                            <span id="lbl_class">{chartsummary.upsent}</span>
                          </p>
                        </Col>
                        <ReactEcharts
                          style={{ height: "100px" }}
                          option={getOption()}
                        />
                      </Row>
                    )}
                  </div>
                  <hr></hr>
                  {showShimmer ? (
                    <ShimmerCategoryList
                      title
                      text
                      cta
                      thumbnailWidth={370}
                      thumbnailHeight={370}
                    />
                  ) : (
                    <div>
                      <AvForm>
                        <div class="row" bis_skin_checked="1">
                          <div
                            class="col-lg-3 col-md-6 col-sm-6 col-xs-7"
                            bis_skin_checked="1"
                          >
                            <AvField
                              type="select"
                              class="form-control"
                              name="selectedclass"
                              onChange={e => handlechnage(e)}
                            >
                              {/* <option value=""> select class</option> */}
                              {studentclasses?.map(stdclas => {
                                return (
                                  <option value={stdclas._id}>
                                    {stdclas.className}
                                  </option>
                                )
                              })}
                            </AvField>
                          </div>

                          <div
                            class="col-lg-3 col-md-4 col-sm-4 col-xs-5"
                            bis_skin_checked="1"
                          >
                            <CSVLink
                              filename={"TableContent.csv"}
                              data={exportData}
                              className="btn btn-success"
                            >
                              <i class="fa fa-print"></i>
                              Download
                            </CSVLink>
                          </div>
                        </div>
                      </AvForm>
                      <div class="table-responsive" bis_skin_checked="1">
                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Developed by 🙋‍♂️
                  <Link href=" #"> Eng zaki ahmed</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ViewAttendence.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(ViewAttendence)
